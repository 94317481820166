import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { Trans } from "react-i18next";
import { useSeeHint } from "components/hint/Hint";
import { FIVEHINT } from "root/main/main/Main";
import { MAIN } from "../../RootRouter.config";
import styled from "styled-components";

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

const StartLearning: React.FC = () => {
  const navigate = useNavigate();
  const seeHint = useSeeHint();

  const handleStartLearning = () => {
    seeHint(FIVEHINT);
    navigate(MAIN.url(), { replace: true });
  };

  return (
    <Wrap>
      <Button size="large" color="primary" variant="contained" onClick={handleStartLearning}>
        <Trans>Start learning</Trans>
      </Button>
    </Wrap>
  );
};

export default StartLearning;
