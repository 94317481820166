import { Button } from "@mui/material";
import Flames from "components/fire/Flames";
import { useSeeHint } from "components/hint/Hint";
import { useSetState } from "components/ReduxProvider";
import React from "react";
import { Trans } from "react-i18next";
import { useEffectOnce } from "react-use";
import { LOSING_XPS_HINT, T_LOSING_XPS } from "sharedJs__generated/constants";
import styled from "styled-components/macro";
import { dispatchEvent, EVENT } from "tools/events";
import { useAnimateTexts } from "../../../../hooks/useAnimateTexts";
import { useViewerQuery } from "../../../../queries/viewerQuery";
import { QuestMessage } from "./misc/QuestMessage";

const Wrap = styled.div``;
const Img = styled.img`
  width: 50px;
  height: 50px;
`;
const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 0 4px;
`;
const SFlames = styled(Flames)`
  transform: scale(1.1);
  filter: hue-rotate(200deg) brightness(1.2);
`;
const Equal = styled.span`
  margin: 0 6px 0 10px;
`;
const SButton = styled(Button).attrs({ variant: "contained", color: "primary" })<{ $hide?: boolean }>`
  margin: 10px 0 4px;
  transition: all 2s;
  opacity: ${({ $hide }) => ($hide ? 0 : 1)};
`;

type Props = {};

const LosingXps: React.FC<Props> = () => {
  const seeHint = useSeeHint();
  const { hintWasSeen } = useViewerQuery();
  const setShowQuestsBackdrop = useSetState("showQuestsBackdrop");
  const [s0] = useAnimateTexts({
    initialDelay: 2000,
    countUpTo: 1,
    disable: hintWasSeen(LOSING_XPS_HINT)
  });

  useEffectOnce(() => {
    setShowQuestsBackdrop(true);
    dispatchEvent(EVENT.updateMain);
  });

  return (
    <QuestMessage>
      <Wrap>
        <Icons>
          <Img src="https://cdn.duocards.com/img/sloth.png" alt="Lazy sloth" />
          <Equal>=&gt;</Equal>
          <SFlames strength={4} xps={-5} />
        </Icons>
        <Trans parent="div">{T_LOSING_XPS}</Trans>
        <SButton
          style={s0}
          onClick={() => {
            seeHint(LOSING_XPS_HINT);
          }}
        >
          <Trans>Got it</Trans>
        </SButton>
      </Wrap>
    </QuestMessage>
  );
};

export default LosingXps;
