import React from "react";
import CardNewDialog from "../../root/main/card/CardNewDialog";
import CardEditDialog from "../cardEditDialog/CardEditDialog";
import CardsMaxDialog from "./CardsMaxDialog";
import DeckCreateDialog from "./DeckCreateDialog";
import FlamesMaxDialog from "./FlamesMaxDialog";
import Goal from "./Goal";
import InviteAcceptDialog from "./InviteAcceptDialog";
import InviteDialog from "./InviteDialog/InviteDialog";
import TooManyDecksDialog from "./TooManyDecksDialog";
import NoCardsDialog from "./NoCardsDialog";
import ReportIssueDialog from "./ReportIssueDialog";
import NotificationDialog from "./NotificationDialog";

type Props = {};

const Dialogs: React.FC<Props> = () => {
  return (
    <>
      <DeckCreateDialog />
      <NotificationDialog />
      <TooManyDecksDialog />
      <InviteDialog />
      <InviteAcceptDialog />
      <CardEditDialog />
      <CardNewDialog />
      <Goal />
      <CardsMaxDialog />
      <FlamesMaxDialog />
      <NoCardsDialog />
      <ReportIssueDialog />
    </>
  );
};

export default Dialogs;
