import React from "react";
import styled from "styled-components/macro";
import MammothWithBubble, { MammothVariant } from "./MammothWithBubble";
import { onboardingStepsData, StepScreenId } from "./onboardingStepsData";
import { useLocation, useNavigate } from "react-router-dom";
import OnboardingStep from "./OnboardingStep";
import { getStepHash, ONBOARDING_HASH_PREFIX } from "./OnboardingRedirect";
import { dispatchEvent, EVENT } from "tools/events";
import useOnboardingData from "hooks/useOnboardingData";
import { ONBOARDING } from "root/RootRouter.config";
import useDisableNavBack from "hooks/useDisableNavBack";
import { useGetState } from "components/ReduxProvider";
const MamothWrap = styled.div<{ $isSmall: boolean }>`
  padding: 0 30px;
  display: flex;
  align-items: ${({ $isSmall }) => ($isSmall ? "flex-start" : "center")};
  width: ${({ $isSmall }) => ($isSmall ? "100%" : "auto")};
`;

const MAX_PROGRESS_VALUE = 100;

const LAST_PROGRESS_STEP_INDEX = onboardingStepsData.findIndex((step) => step.screenId === StepScreenId.ACHIEVEMENTS);

const progressFunction = (stepIndex: number, totalSteps: number): number => {
  if (stepIndex <= 0 || totalSteps <= 0) {
    return 0; // first step not visible and also because of square root
  }

  if (totalSteps - 1 <= stepIndex) {
    return 1;
  }

  const normalizedProgress = stepIndex / totalSteps;
  const progress = Math.sqrt(normalizedProgress); // decrease progress increase over time
  return Math.min(Math.max(progress, 0), 1);
};

const calculateProgressValue = (currentStepIndex: number): number => {
  const totalSteps = LAST_PROGRESS_STEP_INDEX + 1;
  const progressForCurrentStep = progressFunction(currentStepIndex, totalSteps);
  const progress = Math.min(progressForCurrentStep * MAX_PROGRESS_VALUE, MAX_PROGRESS_VALUE);
  return progress;
};

const OnboardingSteps: React.FC = () => {
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0);
  const isDeviceDialogOpen = useGetState("deviceUsersDialogOpen");
  const progressValue = calculateProgressValue(currentStepIndex);
  const currentStep = currentStepIndex > -1 ? onboardingStepsData[currentStepIndex] : null;
  const { getOnboardingData } = useOnboardingData();
  const { latestStepReached } = getOnboardingData();
  const [canContinue, setCanContinue] = React.useState(false);
  const submitFunctionRef = React.useRef<() => Promise<boolean>>();
  const navigate = useNavigate();
  const location = useLocation();
  useDisableNavBack();
  const handleContinue = async () => {
    if (submitFunctionRef.current) {
      const res = await submitFunctionRef.current?.();
      if (!res) return;
    }
    setCanContinue(false);

    submitFunctionRef.current = undefined;
    navigate(ONBOARDING.url() + getStepHash(onboardingStepsData[currentStepIndex + 1].screenId), { replace: true });
  };

  const handleCanSubmitChange = (canContinue: boolean, submitFunction?: () => Promise<boolean>) => {
    setCanContinue(canContinue);
    submitFunctionRef.current = canContinue ? submitFunction : undefined;
  };

  React.useEffect(() => {
    if (latestStepReached === StepScreenId.WELCOME) {
      dispatchEvent(EVENT.refreshNotifications);
    }
  }, [latestStepReached]);

  React.useEffect(() => {
    const stepName = location.hash.replace(ONBOARDING_HASH_PREFIX, "");
    const stepIndex = onboardingStepsData.findIndex((step) => step.screenId === stepName);
    setCurrentStepIndex(stepIndex);
  }, [location]);

  if (!currentStep || isDeviceDialogOpen) {
    return null;
  }

  return (
    <OnboardingStep
      onBack={() => {
        setCurrentStepIndex((prevIndex) => prevIndex - 1);
        navigate(ONBOARDING.url() + getStepHash(onboardingStepsData[currentStepIndex - 1].screenId));
      }}
      showBackButton={currentStepIndex > 0 && !!currentStep.canGoBack}
      showProgressBar={!currentStep.hideProgressBar}
      progressValue={progressValue}
      buttonDisabled={currentStep.optional ? false : !canContinue}
      showContinue={!currentStep.hideSubmitButton}
      buttonOnClick={handleContinue}
    >
      <>
        {currentStep.motivation && (
          <MamothWrap
            $isSmall={currentStep.component && !currentStep.forceLargeMammoth ? true : false}
            key={currentStep.screenId}
          >
            <MammothWithBubble
              variant={
                currentStep.component && !currentStep.forceLargeMammoth ? MammothVariant.Small : MammothVariant.Large
              }
            >
              {currentStep.motivation}
            </MammothWithBubble>
          </MamothWrap>
        )}
        {currentStep.component && (
          <currentStep.component
            onRequestSubmit={handleContinue}
            onCanSubmitChange={handleCanSubmitChange}
            {...currentStep.componentProps}
          />
        )}
      </>
    </OnboardingStep>
  );
};

export default OnboardingSteps;
