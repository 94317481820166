import styled, { css } from "styled-components/macro";
import { Box, Typography, Link, Stack } from "@mui/material";
import { devicePaddings } from "../../../tools/device";
import DialogFullWindow from "../../other/DialogFullWindow";

const paddings = devicePaddings();

export const SDialogFullWindow = styled(DialogFullWindow)`
  background: white;
`;
export const Ul = styled.ul`
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.3em;
  text-align: left;
  margin: 10px 0;
  padding: 0 0 0 10px;
  li {
    margin: 4px;
  }
`;
export const SLink = styled(Link)`
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  color: ${({ theme }) => theme.duo.palette.greyDark};
  text-decoration-color: ${({ theme }) => theme.duo.palette.greyDark};
  cursor: pointer;
`;
export const SColoredHeader = styled.span`
  color: ${({ theme }) => theme.duo.palette.blueMain};
`;
export const SAnimation = styled(Box)`
  height: 400px;
  margin-left: -24px;
  margin-right: -24px;

  ${({ theme }) => css`
    ${theme.breakpoints.down("xs") &&
    css`
      height: 150px;
    `}
    ${theme.breakpoints.down("sm") &&
    css`
      height: 300px;
      margin-bottom: 16px;
    `}
  `}
`;
export const Title = styled(Typography)`
  text-align: center;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: ${paddings.top + 50}px;
`;

export const ButtonsGroup = styled(Stack)`
  width: 400px;
  margin: auto;
  padding-bottom: ${paddings.bottom + 32}px;

  ${({ theme }) => css`
    ${theme.breakpoints.down("xs") &&
    css`
      width: 300px;
    `}
  `}
`;
