import React from "react";

type Props = {
  url: string;
  size?: number;
  style?: React.CSSProperties;
};

const ListItemIcon: React.FC<Props> = ({ url, size = 30, style }) => {
  return <img src={url} alt="icon" width={size} height={size} style={style} />;
};

export default ListItemIcon;
