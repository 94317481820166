import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";
import { useGetState, useSetState } from "components/ReduxProvider";
import { useViewerQuery } from "queries/viewerQuery";
import { useSeeHint } from "components/hint/Hint";
import HelpFindCave, { SHOW_SOURCES_QUEST } from "./HelpFindCave";
import QuestsExplained from "./QuestsExplained";
import TutorialPointer from "root/tutorial/TutorialPointer";
import QuestReward from "../QuestReward";
import { dispatchEvent, EVENT } from "tools/events";
import OwnWordAddButton from "./OwnWordAddButton";
import { QuestMessage } from "../misc/QuestMessage";

export const OWNWORDQUEST = "ownWord";

const STutorialPointer = styled(TutorialPointer)`
  right: 8px;
  bottom: 46px;
  @media (min-width: ${({ theme }) => theme.duo.maxWidth.normal}) {
    right: calc(50% - 392px);
  }
`;

type Props = {};

const OwnWord: React.FC<Props> = () => {
  const { hintWasSeen, getFlag } = useViewerQuery();
  const questsExplained = useGetState("questsExplained");
  const ownWordAdded = useGetState("ownWordAdded");
  const seeHint = useSeeHint();
  const setShowQuestsBackdrop = useSetState("showQuestsBackdrop");

  const forcedOwnWord = getFlag("abtest_forcedOwnWord")?.value === "on";

  React.useEffect(() => {
    (!questsExplained || forcedOwnWord) && setShowQuestsBackdrop(true);
    questsExplained && !ownWordAdded && !forcedOwnWord && setTimeout(() => setShowQuestsBackdrop(false), 1000);
    dispatchEvent(EVENT.updateMain);
  }, [questsExplained, setShowQuestsBackdrop, ownWordAdded, forcedOwnWord]);

  return (
    <QuestMessage>
      {!hintWasSeen(SHOW_SOURCES_QUEST) ? (
        <HelpFindCave />
      ) : !questsExplained ? (
        <QuestsExplained />
      ) : (
        <div>
          <p style={{ margin: "5px 0", textDecoration: ownWordAdded ? "line-through" : "none" }}>
            <Trans>Create at least one word on your own.</Trans>
          </p>

          {!forcedOwnWord && !ownWordAdded && <STutorialPointer pulsing />}
          {forcedOwnWord && !ownWordAdded && <OwnWordAddButton />}

          <QuestReward
            rewardedXps={10}
            claim={ownWordAdded}
            onDone={() => {
              seeHint(OWNWORDQUEST);
              setTimeout(() => dispatchEvent(EVENT.updateMain));
            }}
          />
        </div>
      )}
    </QuestMessage>
  );
};

export const useCreatingNewCard = () => {
  const { hintWasSeen, getFlag } = useViewerQuery();
  const ownWordAdded = useGetState("ownWordAdded");

  const forcedOwnWord = getFlag("abtest_forcedOwnWord")?.value === "on";
  if (!forcedOwnWord) return false;

  return hintWasSeen(SHOW_SOURCES_QUEST) && !hintWasSeen(OWNWORDQUEST) && !ownWordAdded;
};

export default OwnWord;
