import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInviteDialog, selectInviteDialog } from "../../ReduxProvider";
import { useViewerQuery } from "../../../queries/viewerQuery";
import { useMatch } from "react-router";
import { useCanAdvertFriends } from "components/ads/useCanAdvertFriends";
import { InviteDialogContent } from "./InviteDialogContent";
import { useDBLog } from "../../../hooks/useDBLog";
import { useSeeHint } from "../../hint/Hint";
import {
  INVITE_FRIEND_QUEST_PHASE_1,
  INVITE_FRIEND_QUEST_PHASE_2
} from "../../../root/main/main/quests/inviteFriend/InviteFriendQuest.model";
import { MAIN } from "../../../root/RootRouter.config";
import { useNavigate } from "react-router-dom";

export const useInviteHash = (): string | undefined => {
  const iRoute: any = useMatch("/i/:ihash");
  return iRoute?.params?.ihash;
};

const InviteDialog = () => {
  const isDialogOpen = useSelector(selectInviteDialog);
  const dispatch = useDispatch();
  const { viewer, isSubscriber, subscriptionFlags } = useViewerQuery();
  const advertFriends = useCanAdvertFriends();
  const seeHint = useSeeHint();
  const { hintWasSeen } = useViewerQuery();
  const dblog = useDBLog();
  const navigate = useNavigate();

  if (!viewer?.id || !viewer?.name) return null;

  const hash = btoa(viewer.name);

  const handleCloseDialog = () => {
    return dispatch(setInviteDialog(false));
  };

  return (
    <InviteDialogContent
      open={!!isDialogOpen}
      hash={hash}
      canAdvertFriends={advertFriends}
      isSubscriber={isSubscriber && !subscriptionFlags.hasActiveFriendsReward}
      isFromQuest={!hintWasSeen(INVITE_FRIEND_QUEST_PHASE_2) && hintWasSeen(INVITE_FRIEND_QUEST_PHASE_1)}
      onClose={handleCloseDialog}
      onAction={(name) => {
        dblog("invite friends", name);
        seeHint(INVITE_FRIEND_QUEST_PHASE_2);
        navigate(MAIN.url());
      }}
    />
  );
};

export default InviteDialog;
