import { useSeeHint } from "components/hint/Hint";
import { useGetState, useSetState } from "components/ReduxProvider";
import { useViewerQuery, useViewerXps } from "queries/viewerQuery";
import React from "react";
import { Trans } from "react-i18next";
import { useEffectOnce } from "react-use";
import styled from "styled-components/macro";
import { dispatchEvent, EVENT } from "tools/events";
import QuestReward from "./QuestReward";
import TutorialPointer from "../../../tutorial/TutorialPointer";
import Fab from "@mui/material/Fab";
import { faSolidMammoth } from "@awesome.me/kit-55349eb283/icons/kit/custom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QuestMessage } from "./misc/QuestMessage";

export const AI_ASSISTANT_QUEST = "aiAssistantQuest";

const Wrap = styled.div``;

const SDiv = styled.div<{ crossed?: boolean }>`
  text-decoration: ${({ crossed }) => (crossed ? "line-through" : "none")};
`;

const STutorialPointer = styled(TutorialPointer)`
  bottom: 30px;
  right: 88px;

  @media (min-width: ${({ theme }) => theme.duo.maxWidth.normal}) {
    right: calc(50% - 312px);
  }
`;

const SFab = styled(Fab)`
  z-index: 10;
  width: 34px;
  height: 34px;
  margin-left: 5px;
  &,
  &:hover {
    background: ${({ theme }) => theme.duo.color.green};
  }
  svg {
    font-size: 24px;
  }
`;

const REWARD_XP = 5;

type Props = {};

const AiAssistantQuest: React.FC<Props> = () => {
  const { hintWasSeen } = useViewerQuery();
  const questAiAssistant = useGetState("questAiAssistant");
  const seeHint = useSeeHint();
  const { xps } = useViewerXps();
  const setLastQuestXps = useSetState("lastQuestXps");

  useEffectOnce(() => {
    dispatchEvent(EVENT.updateMain);
  });

  return (
    <QuestMessage>
      <Wrap>
        <QuestReward
          rewardedXps={REWARD_XP}
          claim={questAiAssistant}
          onDone={() => {
            seeHint(AI_ASSISTANT_QUEST);
            setTimeout(() => dispatchEvent(EVENT.updateMain));
            setLastQuestXps((xps?.total || 0) + REWARD_XP);
          }}
        />
        <SDiv crossed={questAiAssistant}>
          <Trans parent="div">Try our AI-assistant</Trans>
          <div>
            <Trans>1. Go to the chat</Trans>
            <SFab color="primary">
              <FontAwesomeIcon icon={faSolidMammoth} />
            </SFab>
          </div>
          <Trans parent="div">2. Ask Memo something…</Trans>
        </SDiv>

        {!hintWasSeen(AI_ASSISTANT_QUEST) && <STutorialPointer pulsing />}
      </Wrap>
    </QuestMessage>
  );
};

export default AiAssistantQuest;
