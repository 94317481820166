import React from "react";
import styled, { css, keyframes } from "styled-components/macro";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { Portal } from "@mui/base";
import { useCounter, useDebounce, useEffectOnce, useScrolling } from "react-use";
import { CONTENT_ID } from "components/header/constants";
import useClickAway from "hooks/useClickAway";
import { PortalProps } from "@mui/material";

const pulsingKF = keyframes`
  0%, 100% { transform: scale(1); }
  50% {  transform: scale(1.5); }
`;

const Wrap = styled.div<{ pulsing?: boolean; hide?: boolean }>`
  position: fixed;
  z-index: 1100;
  color: ${({ theme }) => theme.duo.color.green};
  pointer-events: none;
  display: ${({ hide }) => (hide ? "none" : "")};
  transition: opacity 300ms;
  ${({ pulsing }) =>
    pulsing &&
    css`
      animation: ${pulsingKF} 1.2s ease-out infinite;
    `}
`;
const LibCardAppIcon = styled(TouchAppIcon)<{ $rotation: number }>`
  font-size: 56px;
  filter: drop-shadow(0 0 7px #fff);
  transform: rotate(${(p) => p.$rotation}deg);
`;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  pulsing?: boolean;
  delayed?: boolean;
  rotation?: number;
  container?: PortalProps["container"];
};

const TutorialPointer: React.FC<Props> = ({ className, container, style, pulsing, delayed, rotation = 0 }) => {
  const [visible, setVisible] = React.useState(!delayed);
  const [clickedAway, { inc: incClickedAway, reset: resetClickedAway }] = useCounter();
  const ref = React.useRef<any>();

  useDebounce(resetClickedAway, 1500, [clickedAway]);

  useEffectOnce(() => {
    setTimeout(() => setVisible(true), 1300);
  });

  useClickAway(ref, (e) => incClickedAway(), ["mousedown", "touchstart", "mouseup", "touchend"]);

  const el = document.getElementById(CONTENT_ID);
  const scrolling = useScrolling({ current: el } as any);

  React.useEffect(() => {
    if (scrolling) incClickedAway();
  }, [scrolling, incClickedAway]);

  if (!visible) return null;

  return (
    <Portal container={container}>
      <Wrap className={className} style={style} pulsing={pulsing} hide={!!clickedAway}>
        <LibCardAppIcon $rotation={rotation} ref={ref} />
      </Wrap>
    </Portal>
  );
};

export default TutorialPointer;
