import { Button } from "@mui/material";
import { isEqual } from "lodash";
import { useLibraryQuery } from "queries/sources/libraryQuery";
import React from "react";
import { Trans } from "react-i18next";
import TutorialPointer from "root/tutorial/TutorialPointer";
import styled from "styled-components/macro";
import { Difficulty } from "../../../queries/sources/__generated__/editSourceMutation.graphql";
import { useViewerDecks } from "../../../queries/viewerQuery";
import { flag } from "../../../tools/langs";
import { CONTENTTEST_CLS, COURSE_CARD_CLS, SET_CARD_CLS } from "../../library/library/SourceCard";
import { disableLibraryScrolling } from "../misc/helpers";
import { TutorialFC } from "../TutorialContent";
import { useSetFlag } from "queries/users/setFlag";
import { sentryCapture } from "sentry/sentry";
import { contentTestValue, CONTENT_TEST_FLAG } from "sharedJs__generated/constants";
import { fromGlobalId } from "graphql-relay";
import { MY_DIFFICULTY_FLAG } from "queries/users/userFlagsModel";

let captured = false;
const sentryCaptureOnce = (message: string) => {
  if (!captured) sentryCapture(message);
  captured = true;
};

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0 10px;
  button {
    min-width: 150px;
    margin: 5px;
  }
`;

const levels: Difficulty[] = ["beginner", "easy", "moderate", "hard"];

const ChooseSet: TutorialFC = ({ visibleId, setVisibleId }) => {
  const { deck } = useViewerDecks();
  const { sets, courses } = useLibraryQuery();
  const [pointerPos, setPointerPos] = React.useState({ left: 0, top: 0 });
  const [difficulty, setDifficulty] = React.useState<Difficulty>();
  const flagWasSet = React.useRef<boolean>(false);
  const contentTestOn = React.useRef<boolean | null>(null);
  const setFlag = useSetFlag();

  const showSourceCard = (difficulty: Difficulty) => {
    const sets = document.getElementsByClassName(SET_CARD_CLS(difficulty)) as HTMLCollectionOf<HTMLElement>;
    const courses = document.getElementsByClassName(COURSE_CARD_CLS(difficulty)) as HTMLCollectionOf<HTMLElement>;

    let cardElm = courses[0] || sets[0] || null;

    const allElements = [...(courses as any), ...(sets as any)];
    const testElements = allElements.filter((s) => [...s.classList].some((c) => c === CONTENTTEST_CLS));
    if (testElements.length > 1) sentryCaptureOnce("More than 1 contentTests on one screen");
    else if (allElements[0] === testElements[0]) sentryCaptureOnce("ContentTest cannot be on the first position");
    else if (testElements.length === 1) {
      if (contentTestOn.current === null) contentTestOn.current = Math.random() >= 0.5;
      if (contentTestOn.current) {
        cardElm = testElements[0]; // in 50% grabs testing source instead of top one
      }
      if (!flagWasSet.current) {
        const testId = fromGlobalId(testElements[0].getAttribute("data-id") as string).id;
        setFlag({ name: CONTENT_TEST_FLAG, value: contentTestValue(testId, contentTestOn.current) });
        flagWasSet.current = true;
      }
    }

    if (cardElm) {
      cardElm.id = "ChoosenSet";
      setVisibleId("ChoosenSet");
      const parentCont = cardElm?.parentNode?.parentElement;
      if (parentCont) parentCont.style.overflowX = "unset";
      const rect = cardElm.getBoundingClientRect();
      const newPos = { left: rect.left + 30, top: rect.top + 100 };
      !isEqual(newPos, pointerPos) && setPointerPos(newPos);
    }
    disableLibraryScrolling();
  };

  React.useEffect(() => {
    if (difficulty) {
      const iId = setInterval(() => showSourceCard(difficulty), 500);

      return () => clearInterval(iId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [difficulty]);

  if (!sets.data) return null;

  const handleDifficultyClick = (level: Difficulty) => {
    setDifficulty(level);
    setFlag({ name: MY_DIFFICULTY_FLAG, value: level });
  };

  const levelExists = (level: Difficulty) => {
    return (
      sets.data.sources.edges?.some((e) => e?.node?.difficulty === level) ||
      courses.data?.sources.edges?.some((e) => e?.node?.difficulty === level)
    );
  };

  return (
    <>
      {!visibleId ? (
        <>
          <p>{flag(deck?.front)}</p>
          <Trans parent="p">At what level do you want to start?</Trans>
          <Buttons>
            {levels.map(
              (level) =>
                levelExists(level) && (
                  <Button key={level} variant="outlined" color="primary" onClick={() => handleDifficultyClick(level)}>
                    <Trans>{level}</Trans>
                  </Button>
                )
            )}
          </Buttons>
        </>
      ) : (
        <>
          <Trans>Let's grab a few words from a prepared set or cards.</Trans>
          {pointerPos.left && <TutorialPointer pulsing style={pointerPos} />}
        </>
      )}
    </>
  );
};

export default ChooseSet;
