import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Stack, Button } from "@mui/material";
import LoginGuest from "./LoginGuest";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import { bottomPadding, canShowOAuth, isBrowser, isDesktop, isIos, topPadding, isApp } from "../../tools/device";
import DownloadButton from "./DownloadButton";
import LoginHeader from "./LoginHeader";
import OAuth from "components/other/OAuth";
import { useSetState, useGetState } from "components/ReduxProvider";
import EmailAuth from "root/auth/EmailAuth";
import Toggler from "components/other/Toggler";
import { usePreLoginQuery } from "./PreLogin";
import MammothNew from "icons/MammothNew";
import { SecondaryLoginButton } from "root/auth/LoginButton";
import { BrandTitle } from "../../components/other/BrandTitle";
import RegistrationAgreeText from "./RegistrationAgreeText";
import ContinueWithEmailButton from "./ContinueWithEmailButton";
import { dispatchEvent, EVENT } from "tools/events";
import { useEffectOnce } from "react-use";
import { UserAction } from "components/initializers/UserActionsLogger";
import Loader from "../../components/other/Loader";

export const ForceLoginPageHash = "login";
export const LoginPageForced = () => {
  return window.location.hash === `#${ForceLoginPageHash}`;
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  text-align: center;
  padding: ${topPadding}px 0 ${bottomPadding}px;
  .MuiButton-outlined {
    background: white;
  }
`;
const In = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  padding: 5px 24px;
  @media (min-width: 600px) {
    max-width: 400px;
  }
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: stretch;
  margin: 0 auto;
  padding: 5px 24px;
  @media (min-width: 600px) {
    max-width: 400px;
  }
  height: 100%;
`;
const SliderOut = styled.div`
  width: 100%;
  overflow: hidden;
`;
const SliderBox = styled.div`
  width: 50%;
  padding: 8px 0;
`;
const SliderIn = styled.div<{ index: number; size: number }>`
  width: ${(p) => p.size * 100}%;
  position: relative;
  left: ${(p) => p.index * -100}%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: left 0.3s;

  ${SliderBox} {
    width: ${(p) => 100 / p.size}%;
  }
`;
const Small = styled.p`
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.4em;
  color: ${({ theme }) => theme.duo.color.textGrey};
`;
const SContinueWithEmailButton = styled(ContinueWithEmailButton)`
  margin-bottom: 20px;
`;
const SLoader = styled(Loader)`
  height: 125px;
`;

const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: preLoginData, isLoading: preLoginLoading } = usePreLoginQuery();
  const [isEmailOpen, setEmailOpen] = useState(false);
  const [isAuthOpen, setAuthOpen] = useState(!isBrowser());
  const [areLoginOptionsOpen, setLoginOptionsOpen] = useState(false);
  const location = useLocation();
  const [mammothHeight, setMammothHeight] = useState(120);
  const [subtitleVisible, setSubtitleVisible] = useState(true);
  const setToken = useSetState("token");

  // todo: remove whole block below after ab-test
  const abtest_delayedRegistration = useGetState("abtest_delayedRegistration");
  const set_abtest_delayedRegistration = useSetState("abtest_delayedRegistration");
  useEffectOnce(() => {
    const isABTestActive = Math.random() > 0.5;
    set_abtest_delayedRegistration(isABTestActive ? "on" : "off"); // to be stored by dblog "user_action"
  });

  if (preLoginData?.token) {
    setToken(preLoginData.token);
  }

  const toggleEmailMethod = (show: boolean) => {
    setEmailOpen(show);
    navigate({ hash: show ? "email" : "" });
  };

  const handleKeyboardToggle = (isVisible: boolean) => {
    if (isDesktop()) return;
    setSubtitleVisible(!isVisible);
  };

  useEffect(() => {
    setMammothHeight(isEmailOpen && !isDesktop() ? 50 : 120);
  }, [isEmailOpen]);

  useEffect(() => {
    if (location.hash === "#email" && !isEmailOpen) {
      setEmailOpen(true);
    }

    if (location.hash !== "#email" && isEmailOpen) {
      setEmailOpen(false);
    }
  }, [location.hash, isEmailOpen]);

  useEffectOnce(() => {
    dispatchEvent(EVENT.logUserAction, { action: UserAction.signupVisited });
  });

  const googleAuth = <OAuth hideApple />;
  const appleAuth = <OAuth hideGoogle />;
  const emailAuth = <SContinueWithEmailButton onClick={() => toggleEmailMethod(true)} />;
  const guestAuth = <LoginGuest />;

  let authMethods: any[];
  let appleLoginCompliance = false; // following guidelines that apple login must be an equivalent option on iOS
  switch (preLoginData?.preferredLoginMethod) {
    case "apple":
      authMethods = [appleAuth, googleAuth, emailAuth, guestAuth];
      break;
    case "email":
      authMethods = [emailAuth, googleAuth, appleAuth, guestAuth];
      break;
    default:
      if (isIos()) {
        appleLoginCompliance = true;
        authMethods = [googleAuth, emailAuth, guestAuth];
      } else {
        authMethods = [googleAuth, appleAuth, emailAuth, guestAuth];
      }
  }
  if (!canShowOAuth()) {
    authMethods = [emailAuth, guestAuth];
  }
  if (isBrowser() && process.env.NODE_ENV !== "development") {
    authMethods.pop();
  }
  const recommendedAuthMethod = authMethods.shift();

  if (abtest_delayedRegistration === "on") {
    if (preLoginLoading) {
      return null;
    }

    let slideIndex: number;
    if (isBrowser() || areLoginOptionsOpen) {
      slideIndex = isEmailOpen ? 2 : 1;
    } else {
      slideIndex = 0;
    }

    return (
      <Wrap>
        <LoginHeader
          onBack={() => {
            setLoginOptionsOpen(false);
            if (isBrowser()) {
              setEmailOpen(false);
              setAuthOpen(false);
            }
          }}
          showBack={areLoginOptionsOpen && !isEmailOpen && (isApp() || isAuthOpen)}
        />
        <div>
          <Stack alignItems="center" spacing={1}>
            <MammothNew height={mammothHeight} style={{ transition: "height 0.5s ease" }} />
            <BrandTitle>DuoCards</BrandTitle>
            {subtitleVisible && <Trans parent={Small}>Add words from anywhere, never forget them</Trans>}
          </Stack>
        </div>
        {isBrowser() && !isAuthOpen && (
          <>
            <div>
              <DownloadButton>{t("Open App")}</DownloadButton>
            </div>
            {isDesktop() && (
              <div>
                <SecondaryLoginButton
                  onClick={() => {
                    setAuthOpen(true);
                    setLoginOptionsOpen(true);
                  }}
                >
                  {t("Login as an existing user")}
                </SecondaryLoginButton>
              </div>
            )}
          </>
        )}
        <Toggler show={isAuthOpen}>
          <SliderOut>
            <SliderIn size={3} index={slideIndex}>
              <SliderBox>
                <Bottom>
                  <Stack direction="column" spacing={3} sx={{ margin: "0 30px 22px" }}>
                    <div>
                      <Button
                        variant="text"
                        color="inherit"
                        fullWidth
                        onClick={() => setLoginOptionsOpen(!areLoginOptionsOpen)}
                      >
                        {t("I already have an account")}
                      </Button>
                    </div>
                    <div>{guestAuth}</div>
                  </Stack>
                </Bottom>
              </SliderBox>
              <SliderBox>
                <In>
                  <div>{googleAuth}</div>
                  <div>{appleAuth}</div>
                  <div>{emailAuth}</div>
                  {isBrowser() && process.env.NODE_ENV === "development" && guestAuth}
                  {isApp() && <RegistrationAgreeText />} {/* you can only register inside app */}
                </In>
              </SliderBox>
              <SliderBox>
                {isEmailOpen && (
                  <EmailAuth
                    initialEmail={preLoginData?.email || undefined}
                    onBackButton={() => toggleEmailMethod(false)}
                    onFocus={() => handleKeyboardToggle(true)}
                    onBlur={() => handleKeyboardToggle(false)}
                  />
                )}
              </SliderBox>
            </SliderIn>
          </SliderOut>
        </Toggler>
        {isDesktop() && <div></div>} {/* empty to do not stick to bottom on desktop */}
      </Wrap>
    );
  }

  return (
    <Wrap>
      <LoginHeader />
      <div>
        <Stack alignItems="center" spacing={1}>
          <MammothNew height={mammothHeight} style={{ transition: "height 0.5s ease" }} />
          <BrandTitle>DuoCards</BrandTitle>
          {subtitleVisible && <Trans parent={Small}>Add words from anywhere, never forget them</Trans>}
        </Stack>
      </div>
      {isBrowser() && !isAuthOpen && (
        <>
          <div>
            <DownloadButton>{t("Open App")}</DownloadButton>
          </div>
          {isDesktop() && (
            <div>
              <SecondaryLoginButton
                onClick={() => {
                  setAuthOpen(true);
                }}
              >
                {t("Login as an existing user")}
              </SecondaryLoginButton>
            </div>
          )}
        </>
      )}
      {preLoginLoading ? (
        <SLoader />
      ) : (
        <Toggler show={isAuthOpen}>
          <SliderOut>
            <SliderIn size={2} index={isEmailOpen ? 1 : 0}>
              <SliderBox>
                <In>
                  <div>{recommendedAuthMethod}</div>
                  {appleLoginCompliance && <div>{appleAuth}</div>}
                  {authMethods.map((authMethod, i) => (
                    <div key={i}>{authMethod}</div>
                  ))}
                  {isApp() && <RegistrationAgreeText />} {/* you can only register inside app */}
                </In>
              </SliderBox>
              <SliderBox>
                {isEmailOpen && (
                  <EmailAuth
                    initialEmail={preLoginData?.email || undefined}
                    onBackButton={() => toggleEmailMethod(false)}
                    onFocus={() => handleKeyboardToggle(true)}
                    onBlur={() => handleKeyboardToggle(false)}
                  />
                )}
              </SliderBox>
            </SliderIn>
          </SliderOut>
        </Toggler>
      )}
      {isDesktop() && <div></div>} {/* empty to do not stick to bottom on desktop */}
    </Wrap>
  );
};

export default LoginPage;
