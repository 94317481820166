import React from "react";
import styled from "styled-components";
import LinearProgress from "@mui/material/LinearProgress";

const ProgressBarContainer = styled.div`
  width: 100%;
`;

const StyledLinearProgress = styled(LinearProgress)`
  height: 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.duo.color.white};
  border: 1px solid ${({ theme }) => theme.duo.color.lightBlue};

  & .MuiLinearProgress-bar {
    border-radius: 0px;
    background-color: ${({ theme }) => theme.duo.color.lightBlue};
  }
`;

// Define the ProgressBar component
const ProgressBar: React.FC<{ value: number }> = ({ value }) => {
  return (
    <ProgressBarContainer>
      <StyledLinearProgress variant="determinate" value={value} />
    </ProgressBarContainer>
  );
};

export default ProgressBar;
