import styled from "styled-components/macro";
import { IconButton, ButtonProps } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const SIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: -2px;
  color: #aaa;
`;

type Props = ButtonProps;

export function QuestCloseButton(props: Props) {
  return (
    <SIconButton {...props}>
      <CloseIcon />
    </SIconButton>
  );
}
