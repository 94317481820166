import AppLang from "components/other/AppLang";
import styled from "styled-components";
import { topPadding } from "tools/device";
const Wrap = styled.div`
  position: absolute;
  top: ${topPadding}px;
`;

const Welcome: React.FC = () => {
  return (
    <Wrap>
      <AppLang />
    </Wrap>
  );
};

export default Welcome;
