import { GreyArrow, GreyBubble } from "../../../../../components/mammoth/GreyBubble";
import React from "react";
import styled from "styled-components/macro";

const BubbleWrap3 = styled.div`
  margin: 0 -8px -20px;
`;
const BubbleWrap2 = styled.div`
  margin: 0 auto;
  width: 360px;
`;
const BubbleWrap = styled.div`
  margin: 0 auto 0 0;
  width: 246px;
`;
const SGreyBubble = styled(GreyBubble)`
  margin: 0 auto 0 0;
  background: ${({ theme }) => theme.duo.color.lightGrey}66;
  z-index: 3;
`;

type Props = {
  children: React.ReactNode;
  petting?: boolean;
};

export function QuestBubble({ children, petting }: Props) {
  return (
    <BubbleWrap3>
      <BubbleWrap2>
        <BubbleWrap>
          <SGreyBubble hidden={petting}>
            {children}
            <GreyArrow />
          </SGreyBubble>
        </BubbleWrap>
      </BubbleWrap2>
    </BubbleWrap3>
  );
}
