import React from "react";
import ViewerWrapper from "./root/ViewerWrapper";
import { BrowserRouter } from "react-router-dom";
import Sharing from "./components/cordova/Sharing";
import ErrorBoundary from "./components/errorBoundary/ErrorBoundary";
import UrlFix from "./components/initializers/UrlFix";
import RelayWrapper from "./graphql/RelayWrapper";
import SnackbarProvider from "./components/initializers/SnackbarProvider";
import Linking from "./components/cordova/Linking";
import Dialogs from "./components/dialogs/Dialogs";
import { GlobalStyle } from "./styled/GlobalStyle";
import SubscriptionRegister from "./root/profile/subscription/SubscriptionRegister";
import AdsLoader from "./components/ads/AdsLoader";
import FireChecker from "./components/fire/FireChecker";
import NotificationsRegister from "./components/cordova/NotificationsRegister";
import Verifying from "./root/other/Verifying";
import Unsubscribe from "./root/other/Unsubscribe";
import ThemesProvider from "./styled/ThemeProvider";
import ViewportKiller from "./components/initializers/ViewportKiller";
import FirebaseInitializer from "components/initializers/FirebaseInitializer";
import IframeCatcher from "components/initializers/IframeCatcher";
import RootRouterWrapper from "root/RootRouterWrapper";
import QueriesToRedux from "queries/QueriesToRedux";
import ReduxProvider from "components/ReduxProvider";
import ReduxAfterInitializer from "redux/ReduxAfterInitializer";
import LayoutWrapper from "components/initializers/LayoutWrapper";
import ReleaseUpdater from "components/initializers/ReleaseUpdater";
import ViewerRefresher from "components/initializers/ViewerRefresher";
import Smartlook from "components/initializers/Smartlook";
import LoaderGlobal from "components/other/LoaderGlobal";
import ConsentInitializer from "components/initializers/ConsentInitializer";
import CourseView from "root/library/source/course/CourseView";
import IdbToRedux from "dexieDB/IdbToRedux";
import ReferrerCounter from "components/initializers/ReferrerCounter";
import DeviceUsersDialog from "./components/dialogs/DeviceUsersDialog";
import Tutorial from "root/tutorial/TutorialSwitch";
import AssistantPopup from "./components/assistant/AssistantPopup";
import IosAudioFix from "components/speech/IosAudioFix";
import DeckSetter from "root/main/deck/DeckSetter";
import ConnectionLogger from "components/initializers/ConnectionLogger";
import LogWrongPrice from "./components/initializers/LogWrongPrice";
import AudioInput from "./components/speech/AudioInput";
import ConsoleLogUI from "components/other/ConsoleLogUI";
import UserActionsLogger from "components/initializers/UserActionsLogger";

const App: React.FC<{}> = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <ReduxProvider>
          <ReduxAfterInitializer />
          <ThemesProvider>
            <LayoutWrapper>
              <RelayWrapper>
                <IosAudioFix />
                <LoaderGlobal />
                <Smartlook />
                <IframeCatcher />
                <FirebaseInitializer />
                <UrlFix />
                <ViewportKiller />
                <GlobalStyle />
                <Sharing />
                <Linking />
                <Verifying />
                <Unsubscribe />
                <QueriesToRedux />
                <IdbToRedux />
                <CourseView />
                <DeviceUsersDialog />
                <ConnectionLogger />
                <ReferrerCounter />
                <ConsentInitializer />
                <UserActionsLogger />
                <AudioInput />
                <ReleaseUpdater>
                  <ViewerWrapper>
                    <NotificationsRegister />
                    <LogWrongPrice />
                    <AssistantPopup />
                    <DeckSetter />
                    <ViewerRefresher />
                    <Dialogs />
                    <FireChecker />
                    <SubscriptionRegister />
                    <RootRouterWrapper />
                    <AdsLoader />
                    <Tutorial />
                    <ConsoleLogUI />
                  </ViewerWrapper>
                </ReleaseUpdater>
                <SnackbarProvider />
              </RelayWrapper>
            </LayoutWrapper>
          </ThemesProvider>
        </ReduxProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
