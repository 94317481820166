import React from "react";
import styled from "styled-components/macro";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import { selectDeviceId, useGetState, useSetState } from "components/ReduxProvider";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Flames from "../fire/Flames";
import { LoginMethodEnum } from "../../sharedJs__generated/constants";
import { useSelector } from "react-redux";
import { usePreLoginQuery } from "../../root/auth/PreLogin";
import { useLogout } from "../../queries/users/logout";
import { useViewerQuery } from "queries/viewerQuery";
import { useEffectOnce } from "react-use";
import { useDeviceLang } from "../../hooks/deviceHooks";
import { useDeleteUserMutation } from "../../queries/users/deleteUser";
import MammothIcon from "../../icons/MammothIcon";
import DialogFullWindow from "components/other/DialogFullWindow";
import Row from "components/other/Row";
import CrownIcon from "../../icons/CrownIcon";
const Content = styled(Grid)`
  margin: 20px;
  overflow: auto;
`;
const SButton = styled(Button)`
  margin: 7px 0;
`;
const SMammothIcon = styled(MammothIcon)`
  width: 70px;
  height: 70px;
  margin: 20px 0;
`;
const Title = styled.div`
  margin: 20px 30px;
  text-align: center;
`;
const SDialogFullWindow = styled(DialogFullWindow)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
const Bottom = styled.div`
  margin: 0 20px;
`;
const SRow = styled(Row)`
  padding: 10px;
`;
const SFlames = styled(Flames)`
  margin-left: auto;
`;
const Middle = styled.div`
  margin-left: 5px;
`;
const SImgWrap = styled.div`
  width: 30px;
`;
const SCrownWrap = styled.div`
  width: 20px;
  height: 20px;
`;
const SMailOutlineIcon = styled(MailOutlineIcon)`
  width: 20px;
  height: 20px;
`;

const DeviceUsersDialog: React.FC = () => {
  const { data: preLoginData, isLoading: preLoginLoading } = usePreLoginQuery();
  const deviceId = useSelector(selectDeviceId);
  const logout = useLogout();
  const deleteUser = useDeleteUserMutation();
  const { viewer, isLoading: isLoadingViewer } = useViewerQuery();
  const token = useGetState("token");
  const dialogOpen = useGetState("deviceUsersDialogOpen");
  const setDialogOpen = useSetState("deviceUsersDialogOpen");
  const { appLang } = useDeviceLang();
  const [prevToken, setPrevToken] = React.useState<string | undefined>("");
  const [prevEmail, setPrevEmail] = React.useState<string | undefined | null>(undefined);

  useEffectOnce(() => {
    setPrevToken(token);
    setPrevEmail(viewer?.email);
  });

  React.useEffect(() => {
    if (
      token &&
      deviceId && // only on mobile
      !viewer?.subscription && // only if he is not subscribed
      !preLoginLoading &&
      !isLoadingViewer && // viewer is taking longer then preLogin, wait for both to finish
      prevToken !== token && // only for new login/registration
      prevEmail !== null && // only if he was not a guest changed to user
      preLoginData?.users &&
      viewer?.email !== undefined &&
      preLoginData?.users?.length > 0 && // only if there are other users
      !(preLoginData?.users?.length === 1 && preLoginData?.users[0].email === viewer?.email) // and the one user is not him
    ) {
      setDialogOpen(true);
      setPrevToken(token);
      setPrevEmail(viewer?.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, preLoginLoading, viewer?.email, isLoadingViewer]);

  return (
    <SDialogFullWindow open={!!dialogOpen}>
      <div />
      <Title>
        <Box textAlign="center">
          <SMammothIcon />
        </Box>
        <Trans>
          Memo noticed there are other accounts connected to this device. Just to confirm, are you sure you're using the
          right one?
        </Trans>
      </Title>
      <Content>
        {preLoginData?.users?.map((user) => (
          <SRow key={user.email}>
            <SImgWrap>
              {user.loginMethod === LoginMethodEnum.google && (
                <img src="https://www.google.com/favicon.ico" alt="google icon" width="20" height="20" />
              )}
              {user.loginMethod === LoginMethodEnum.apple && (
                <img src="https://www.apple.com/favicon.ico" alt="apple icon" width="20" height="20" />
              )}
              {user.loginMethod === LoginMethodEnum.email && <SMailOutlineIcon />}
              {user.subscription && (
                <SCrownWrap>
                  <CrownIcon />
                </SCrownWrap>
              )}
            </SImgWrap>
            <Middle>
              {user.email} <br />
              {user.lastLoginAt && (
                <Typography variant="caption">{new Date(user.lastLoginAt).toLocaleDateString(appLang)}</Typography>
              )}
            </Middle>

            <SFlames strength={5} xps={user.flamesCount} />
          </SRow>
        ))}
      </Content>
      <Bottom>
        <SButton color="primary" fullWidth variant="contained" onClick={() => setDialogOpen(false)}>
          {viewer?.email === null && <Trans>Continue as guest</Trans>}
          {viewer?.email !== null && (
            <>
              <Trans>Continue with email</Trans> {viewer?.email}
            </>
          )}
        </SButton>
        <SButton
          fullWidth
          color="primary"
          variant="outlined"
          onClick={() => {
            logout();
            if (viewer?.createdBeforeSeconds && viewer?.createdBeforeSeconds < 60 && !viewer?.emailVerified) {
              deleteUser();
            }
            setDialogOpen(false);
            setPrevToken(undefined);
            setPrevEmail(undefined);
          }}
        >
          <Trans>Continue with other account</Trans>
        </SButton>
      </Bottom>
    </SDialogFullWindow>
  );
};

export default DeviceUsersDialog;
