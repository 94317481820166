import React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import { LoginGuestMutation } from "./__generated__/LoginGuestMutation.graphql";
import { useMutation } from "relay-hooks";
import { SecondaryLoginButton } from "./LoginButton";
import { useSetState, useGetState } from "components/ReduxProvider";
import { dispatchEvent, EVENT } from "tools/events";
import { UserAction } from "components/initializers/UserActionsLogger";
import { Button } from "@mui/material";

const mutation = graphql`
  mutation LoginGuestMutation {
    loginGuest {
      token
    }
  }
`;

const ButtonWrap = styled.div`
  margin: 0;
`;

const LoginGuest = () => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = React.useState(false);
  const setToken = useSetState("token");
  const abtest_delayedRegistration = useGetState("abtest_delayedRegistration");

  const [loginGuest] = useMutation<LoginGuestMutation>(mutation, {
    onCompleted: ({ loginGuest }) => {
      if (loginGuest) {
        setToken(loginGuest.token);
        dispatchEvent(EVENT.logUserAction, { action: UserAction.userAuthorized, detail: { type: "guest" } });
      } else {
        setSubmitting(false);
        dispatchEvent(EVENT.logUserAction, { action: UserAction.userAuthorizedFailed, detail: { type: "guest" } });
      }
    },
    onError: () => setSubmitting(false)
  });

  const handleGuest = (e) => {
    e.preventDefault();
    loginGuest({ variables: {} });
    setSubmitting(true);
  };

  if (abtest_delayedRegistration === "on") {
    return (
      <Button variant="contained" color="primary" size="large" fullWidth onClick={handleGuest} disabled={submitting}>
        {t("Continue")}
      </Button>
    );
  }

  return (
    <ButtonWrap>
      <SecondaryLoginButton onClick={handleGuest} disabled={submitting}>
        {t("Continue as a guest")}
      </SecondaryLoginButton>
    </ButtonWrap>
  );
};

export default LoginGuest;
