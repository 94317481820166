import { Trans } from "react-i18next";
import { questionsData } from "../questions/questions";
import OnboardingQuestion from "../questions/OnboardingQuestion";
import AchievementList from "./AchievementList";
import CoursePreparationLoader from "./CoursePreparationLoader";
import LanguagesSelect from "./LanguagesSelect";
import PreparedSource from "./PreparedSource";
import StartLearning from "./StartLearning";
import Welcome from "./Welcome";
import { useTranslation } from "react-i18next";

export type OnboardingStepComponentProps = {
  onCanSubmitChange?: (canSubmit: boolean, submitFunction?: () => Promise<boolean>) => void;
  onRequestSubmit?: () => void;
};

export type OnboardingStepDataType<T extends React.FC<any> | undefined = undefined> = {
  screenId: StepScreenId;
  hideProgressBar?: boolean;
  hideSubmitButton?: boolean;
  motivation?: React.ReactNode;
  component?: T;
  componentProps?: T extends React.FC<infer P> ? P : never;
  optional?: boolean;
  canGoBack?: boolean;
  forceLargeMammoth?: boolean;
};

export enum StepScreenId {
  WELCOME = "welcome",
  WELCOME_2 = "welcome-2",
  QUESTIONS_LANGUAGE = "questions-language",
  QUESTIONS_LANGUAGE_2 = "questions-language-2",
  QUESTIONS_LEVEL = "questions-level",
  QUESTIONS_REFERRALS = "questions-referrals",
  QUESTIONS_INTERESTS = "questions-interests",
  QUESTIONS_AGE = "questions-age",
  COURSE_PREPARATION = "course-preparation",
  FIRST_WEEK = "first-week",
  ACHIEVEMENTS = "achievements",
  PREPARED_COURSE = "prepared-course",
  PREPARED_COURSE_OPENED = "prepared-course-opened", // virtual step just to track that the course was opened
  PREPARED_VIDEO = "prepared-video",
  PREPARED_ARTICLE = "prepared-article",
  PREPARED_VIDEO_OPENED = "prepared-video-opened", // virtual step
  PREPARED_ARTICLE_OPENED = "prepared-article-opened", // virtual step
  START_LEARNING = "start-learning"
}

export const getNearestNonVirtualStep = (step: StepScreenId) => {
  if (step === StepScreenId.PREPARED_COURSE_OPENED) return StepScreenId.PREPARED_COURSE;
  if (step === StepScreenId.PREPARED_VIDEO_OPENED) return StepScreenId.PREPARED_VIDEO;
  if (step === StepScreenId.PREPARED_ARTICLE_OPENED) return StepScreenId.PREPARED_ARTICLE;
  return step;
};

// for refreshing when language changes, simple Trans component doesn't work
const TranslatedText: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  return <Trans t={t}>{children}</Trans>;
};

export const onboardingStepsData: OnboardingStepDataType<React.FC<any>>[] = [
  {
    screenId: StepScreenId.WELCOME,
    motivation: (
      <TranslatedText>
        Hi! I’m <b>Memo</b>, and I’ll be guiding you through the app.
      </TranslatedText>
    ),
    hideProgressBar: true,
    optional: true,
    forceLargeMammoth: true,
    component: () => <Welcome />
  },
  {
    screenId: StepScreenId.WELCOME_2,
    motivation: <TranslatedText>Just a few quick questions before we get started with learning!</TranslatedText>,
    optional: true
  },
  {
    screenId: StepScreenId.QUESTIONS_LANGUAGE,
    motivation: (
      <TranslatedText>
        What <b>language</b> are we going to learn together?
      </TranslatedText>
    ),
    optional: true,
    component: LanguagesSelect,
    componentProps: {
      step: "language-select-1"
    }
  } as OnboardingStepDataType<typeof LanguagesSelect>,
  {
    screenId: StepScreenId.QUESTIONS_LANGUAGE_2,
    motivation: (
      <TranslatedText>
        What <b>language</b> do you speak?
      </TranslatedText>
    ),
    component: LanguagesSelect,
    componentProps: {
      step: "language-select-2"
    }
  } as OnboardingStepDataType<typeof LanguagesSelect>,
  {
    screenId: StepScreenId.QUESTIONS_LEVEL,
    motivation: (
      <TranslatedText>
        What's your current <b>level</b>?
      </TranslatedText>
    ),
    component: OnboardingQuestion,
    componentProps: questionsData.level,
    optional: false
  } as OnboardingStepDataType<typeof OnboardingQuestion>,
  {
    screenId: StepScreenId.QUESTIONS_REFERRALS,
    motivation: <TranslatedText>Where did you learn about Duocards app?</TranslatedText>,
    component: OnboardingQuestion,
    componentProps: questionsData.referrals,
    optional: false
  } as OnboardingStepDataType<typeof OnboardingQuestion>,
  {
    screenId: StepScreenId.QUESTIONS_INTERESTS,
    motivation: (
      <TranslatedText>
        What are <b>your interests</b>? Choose one or more.
      </TranslatedText>
    ),
    component: OnboardingQuestion,
    componentProps: questionsData.interests,
    optional: false
  } as OnboardingStepDataType<typeof OnboardingQuestion>,
  // {
  //   screenId: StepScreenId.QUESTIONS_AGE,
  //   component: OnboardingQuestion,
  //   optional: false,
  //   canGoBack: true,
  //   motivation: (
  //     <Trans>
  //       <b>How old</b> are you?
  //     </Trans>
  //   ),
  //   componentProps: questionsData.age
  // } as OnboardingStepDataType<typeof OnboardingQuestion>,
  {
    screenId: StepScreenId.COURSE_PREPARATION,
    component: CoursePreparationLoader,
    hideProgressBar: true,
    hideSubmitButton: true
  } as OnboardingStepDataType<typeof CoursePreparationLoader>,
  {
    screenId: StepScreenId.FIRST_WEEK,
    motivation: (
      <TranslatedText>
        You will learn <b>50 new words</b> in the first week!
      </TranslatedText>
    ),
    optional: true
  } as OnboardingStepDataType<typeof AchievementList>,
  {
    screenId: StepScreenId.ACHIEVEMENTS,
    motivation: <TranslatedText>Let’s take a look at what you will achieve in the first three months!</TranslatedText>,
    component: AchievementList,
    hideProgressBar: false,
    optional: true
  } as OnboardingStepDataType<typeof AchievementList>,
  {
    screenId: StepScreenId.PREPARED_COURSE,
    motivation: <TranslatedText>Your learning plan is ready! I hope you’ll like it.</TranslatedText>,
    component: PreparedSource,
    componentProps: {
      type: "setOrCourse",
      paywall: true
    },
    hideSubmitButton: true,
    hideProgressBar: true
  } as OnboardingStepDataType<typeof PreparedSource>,
  {
    screenId: StepScreenId.PREPARED_VIDEO,
    motivation: <TranslatedText>Now let’s take a look at how to add words from videos and movies!</TranslatedText>,
    component: PreparedSource,
    componentProps: {
      type: "video"
    },
    hideSubmitButton: true,
    hideProgressBar: true
  } as OnboardingStepDataType<typeof PreparedSource>,
  {
    screenId: StepScreenId.PREPARED_ARTICLE,
    motivation: <TranslatedText>Now let’s take a look at how to add words from articles!</TranslatedText>,
    component: PreparedSource,
    componentProps: {
      type: "article"
    },
    hideSubmitButton: true,
    hideProgressBar: true
  } as OnboardingStepDataType<typeof PreparedSource>,
  {
    screenId: StepScreenId.START_LEARNING,
    motivation: <TranslatedText>Great, you just created your deck of cards. Let’s start learning!</TranslatedText>,
    hideSubmitButton: true,
    hideProgressBar: true,
    forceLargeMammoth: true,
    component: StartLearning
  } as OnboardingStepDataType<typeof StartLearning>
];
