import useJsonFlag from "./useJsonFlag";
import { USER_PROFILE_FLAG } from "queries/users/userFlagsModel";

type UserProfileData = {
  age: string;
  interests: string[];
  referrals: string[];
};

const DEFAULT_USER_PROFILE_DATA: UserProfileData = {
  age: "",
  interests: [],
  referrals: []
};

const useUserProfile = () => {
  const { getFlagData: getUserProfileData, saveFlagData: saveUserProfileData } = useJsonFlag<UserProfileData>(
    USER_PROFILE_FLAG,
    DEFAULT_USER_PROFILE_DATA
  );

  return {
    saveUserProfileData,
    getUserProfileData
  };
};

export default useUserProfile;
