import React from "react";
import styled from "styled-components/macro";
import DialogWindow from "../other/DialogWindow";
import { useDispatch } from "react-redux";
import { Trans } from "react-i18next";
import { Button } from "@mui/material";
import { useViewerQuery, useViewerFriends } from "../../queries/viewerQuery";
import { useNavigate } from "react-router";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { InviteAcceptDialogMutation } from "./__generated__/InviteAcceptDialogMutation.graphql";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { InviteAcceptDialogCnfMutation } from "./__generated__/InviteAcceptDialogCnfMutation.graphql";
import { InviteAcceptDialogSIMutation } from "./__generated__/InviteAcceptDialogSIMutation.graphql";
import { setInviteDialog } from "../../components/ReduxProvider";
import { isDesktop } from "../../tools/device";
import { useInviteHash } from "components/dialogs/InviteDialog/InviteDialog";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { FRIENDSREWARD } from "../../sharedJs__generated/constants";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";

const InviteAcceptQL = graphql`
  mutation InviteAcceptDialogMutation($hash: String) {
    inviteAccept(hash: $hash) {
      viewer {
        ...viewerQuery_friends
        inviter {
          name
          deviceId
        }
        subscriptions {
          transactionId
          timeToExpire
          platform
          storeId
          family
        }
      }
      inviter {
        name
        deviceId
      }
      addedMonths
    }
  }
`;
const confirmFriendshipsQL = graphql`
  mutation InviteAcceptDialogCnfMutation {
    confirmFriendhips {
      viewer {
        ...viewerQuery_friends
        subscriptions {
          transactionId
          timeToExpire
          platform
          storeId
          family
        }
      }
      newFriends {
        friend {
          name
        }
      }
      addedMonths
    }
  }
`;
const setInviterQL = graphql`
  mutation InviteAcceptDialogSIMutation($hash: String!) {
    setInviter(hash: $hash) {
      inviter {
        name
        deviceId
      }
    }
  }
`;

const SDialogWindow = styled(DialogWindow)`
  padding-bottom: 20px !important;
`;
const SIcon = styled(SupervisedUserCircleIcon)`
  color: ${({ theme }) => theme.duo.color.green};
  font-size: 40px;
`;
const Green = styled.span`
  color: ${({ theme }) => theme.duo.color.green};
  font-size: 105%;
`;
const Confets = styled.div`
  font-size: 40px;
  line-height: 1.5em;
  margin-bottom: 15px;
`;

type Props = {};

const InviteAcceptDialog: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { viewer, hintWasSeen } = useViewerQuery();
  const { friends } = useViewerFriends();
  const [inviter, setInviter] = React.useState<string | null>();
  const [inviteAccept, { loading }] = useMutation<InviteAcceptDialogMutation>(InviteAcceptQL);
  const [confirmFriendships] = useMutation<InviteAcceptDialogCnfMutation>(confirmFriendshipsQL);
  const navigate = useNavigate();
  const [claimedMonths, setClaimedMonths] = React.useState(0);
  const [updateInviter, { loading: loadingSI }] = useMutation<InviteAcceptDialogSIMutation>(setInviterQL);
  const accepted = React.useRef(false);

  const hash: string | undefined = useInviteHash();

  const claimMonths = (months: number) => {
    setClaimedMonths(months);
    months && navigate(SUBSCRIPTION.url());
  };

  React.useEffect(() => {
    if (viewer && !loading && !loadingSI) {
      if (
        (viewer.inviter || hash) &&
        hintWasSeen(TUTORIALDONE) &&
        viewer.emailVerified &&
        viewer.deviceId &&
        !accepted.current
      ) {
        accepted.current = true;
        inviteAccept({
          variables: { hash },
          onCompleted: ({ inviteAccept }) => {
            if (inviteAccept?.inviter) {
              claimMonths(Number(inviteAccept.addedMonths));
              setInviter(inviteAccept.inviter.name);
            }
          }
        });
      }
      //
      else if (hash && !viewer.inviter && (viewer.deviceId || isDesktop()) && !accepted.current) {
        updateInviter({ variables: { hash: hash } });
      }
      hash && navigate("/", { replace: true });
    }
  });

  React.useEffect(() => {
    if (
      viewer &&
      viewer.emailVerified &&
      !accepted.current &&
      friends?.edges?.some((f) => f.shouldBeConfirmed) &&
      viewer.deviceId
    ) {
      accepted.current = true;
      confirmFriendships({
        variables: {},
        onCompleted: ({ confirmFriendhips }) => {
          claimMonths(confirmFriendhips.addedMonths);
          setInviter(confirmFriendhips.newFriends.map((i) => i.friend.name).join(", "));
        }
      });
    }
  });

  if (!viewer?.id) return null;

  return (
    <>
      <SDialogWindow open={!!inviter} onClose={() => setInviter(undefined)} wide>
        <SIcon />
        <p>
          <strong>
            <Trans>Great, now you are friends with</Trans> <Green>{inviter}</Green>!
          </strong>
        </p>
        {!!claimedMonths && (!viewer.subscription || viewer.subscription.storeId === FRIENDSREWARD) && (
          <>
            <Confets>{"🎉"}</Confets>
            <p>
              <strong>
                <Trans>And as we promised, we just credited you a month of Premium for free</Trans>!{" "}
                {claimedMonths > 1 ? `${claimedMonths}x!!` : ""}
              </strong>
            </p>
            <p>
              <Trans>Invite more friends and get up to 24 months of free Premium</Trans>
            </p>
          </>
        )}
        <div>
          <Button color="primary" variant="contained" onClick={() => setInviter(undefined)}>
            Ok
          </Button>
        </div>
        <Button
          color="secondary"
          onClick={() => {
            setInviter(undefined);
            dispatch(setInviteDialog(true));
          }}
        >
          <Trans>Invite friends</Trans>
        </Button>
      </SDialogWindow>
    </>
  );
};

export default InviteAcceptDialog;
