import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components/macro";
import { useViewerQuery, useViewerXps } from "../../queries/viewerQuery";
import Avatar from "./Avatar";
import Friends from "./Friends";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useRefetchable } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import FriendshipChecklist, { useShowFriendshipChecklist } from "../main/main/FriendshipChecklist";
import VerifyButton from "./VerifyButton";
import { Button } from "@mui/material";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import { setInviteDialog } from "components/ReduxProvider";
import { USEREDIT } from "./ProfileRouter.config";
import {
  INVITE_FRIEND_QUEST_PHASE_1,
  INVITE_FRIEND_QUEST_PHASE_2
} from "../main/main/quests/inviteFriend/InviteFriendQuest.model";
import TutorialPointer from "../tutorial/TutorialPointer";

const fragmentSpec = graphql`
  fragment MeAndFriends_friends on User @refetchable(queryName: "viewerRefetchQuery") {
    ...viewerQuery_friends
  }
`;

const Wrap = styled.div`
  position: relative;
  background: white;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
`;
const Me = styled.div`
  padding: 20px 0 10px;
  margin: 0 20px 0;
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.duo.color.grey};
`;
const Name = styled.div`
  font-weight: bold;
`;
const SButton = styled(Button)`
  margin: -7px auto 5px;
`;
const SRefreshIcon = styled(RefreshIcon)`
  font-size: 17px;
  color: #ccc;
  position: absolute;
  left: 25px;
  bottom: 17px;
`;
const SFriendshipChecklist = styled(FriendshipChecklist)`
  padding: 0 20px 15px;
`;
const STutorialPointer = styled(TutorialPointer)`
  top: 10px;
  left: calc(50% - 40px);
  position: absolute;
`;

const MeAndFollowing: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { viewer, hintWasSeen } = useViewerQuery();
  const { xps } = useViewerXps();
  const { refetch, isLoading } = useRefetchable(fragmentSpec, null as any);
  const showFriendshipChecklist = useShowFriendshipChecklist();
  const dispatch = useDispatch();
  const container = React.useRef(null);

  if (!viewer) return null;

  return (
    <Wrap>
      <Me onClick={() => navigate(USEREDIT.url())}>
        <Avatar user={{ ...viewer, xps: { total: xps?.total } }} />
        <Name>{viewer.name}</Name>
        <div>{viewer.email}</div>
        {!viewer.emailVerified && <VerifyButton />}
      </Me>

      {showFriendshipChecklist ? (
        <SFriendshipChecklist />
      ) : (
        <>
          <div style={{ opacity: isLoading ? 0.4 : 1 }}>
            <Friends />
          </div>
          {!isLoading && <SRefreshIcon onClick={() => refetch({ id: viewer.id })} />}
          <SButton color="secondary" onClick={() => dispatch(setInviteDialog(true))} ref={container}>
            <Trans>Connect with friends</Trans>
          </SButton>
        </>
      )}

      {hintWasSeen(INVITE_FRIEND_QUEST_PHASE_1) && !hintWasSeen(INVITE_FRIEND_QUEST_PHASE_2) && (
        <STutorialPointer pulsing rotation={45} container={() => container.current!} />
      )}
    </Wrap>
  );
};

export default MeAndFollowing;
