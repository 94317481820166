import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { cardCreateMutation, cardCreateMutation$data } from "./__generated__/cardCreateMutation.graphql";
import { useViewerDecks, useViewerQuery, Viewer } from "../viewerQuery";
import { useDispatch } from "react-redux";
import { setCanRecommend, setLearnLimit, useSetState } from "../../components/ReduxProvider";
import { useMatch, useNavigate } from "react-router";
import { LEARN } from "../../root/main/MainRouter";
import { createSCardMutation } from "../sources/__generated__/createSCardMutation.graphql";
import { CreateSCard } from "../sources/createSCard";
import { FIRSTLOOPDONE } from "../../root/main/learn/Learn";
import { useReachedMaximum } from "../../components/dialogs/CardsMaxDialog";
import { useSeeHint } from "components/hint/Hint";
import { FIVEHINT } from "root/main/main/Main";
import { FIRSTCARDSCOUNT } from "root/tutorial/Tutorial";
import { Trans } from "react-i18next";
import { snackbar } from "tools/events";
import { Box, Button } from "@mui/material";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { viewerQuery_decks$data } from "../__generated__/viewerQuery_decks.graphql";
import { RecordSourceSelectorProxy } from "relay-runtime";
import { MAIN, PROFILE } from "../../root/RootRouter.config";

const mutation = graphql`
  mutation cardCreateMutation(
    $deckId: ID!
    $front: String!
    $back: String!
    $langBack: String
    $hint: String
    $sCardId: ID
    $sBackId: ID
    $sourceId: ID
    $deleted: Boolean
    $svg: FlatIconEdit
    $returnDeck: Boolean
  ) {
    cardCreate(
      deckId: $deckId
      front: $front
      back: $back
      langBack: $langBack
      hint: $hint
      sCardId: $sCardId
      sBackId: $sBackId
      sourceId: $sourceId
      deleted: $deleted
      returnDeck: $returnDeck
      svg: $svg
    ) {
      duplicatedCard {
        id
        front
        back
      }
      deck {
        id
        stats {
          total
          known
          unknown
          completed
        }
      }
    }
  }
`;

export interface extendedVariables {
  noDeck?: boolean;
  onComplete?: (data) => void;
}

export const useCardCreate = () => {
  const { viewer, hintWasSeen, getFlag } = useViewerQuery();
  const { deck } = useViewerDecks();
  const dispatch = useDispatch();
  const inLearn = useMatch(LEARN.url());
  const reachedMaximum = useReachedMaximum();
  const navigate = useNavigate();
  const seeHint = useSeeHint();
  const setDeckId = useSetState("deckId");

  const [createCard, { data: cardData, loading: cardLoading }] = useMutation<cardCreateMutation>(mutation);
  const [createSCard, { data: sCardData, loading: sCardLoading }] = useMutation<createSCardMutation>(CreateSCard);

  const smallerPickerLoop = getFlag("abtest_smallerPickerLoop")?.value !== "off";

  const cardCreate = (variables: cardCreateMutation["variables"] & extendedVariables, isLastSetCard?: boolean) => {
    const { onComplete, noDeck } = variables;

    if (reachedMaximum && !noDeck) {
      dispatch(setLearnLimit(true));
      return false;
    }

    if (noDeck && variables.sourceId) {
      return createSCard({
        variables: { ...variables, sourceId: variables.sourceId, backLang: deck?.back },
        onCompleted: (data) => {
          onComplete && onComplete(data);
        }
      });
    }
    // when user is adding 6th card, we will refresh the deck to look for update `unknown` count
    const returnDeck = deck?.stats.unknown === 5 && variables.deleted === false && smallerPickerLoop;
    return createCard({
      variables: {
        ...variables,
        langBack: deck?.back,
        svg: variables.svg,
        returnDeck
      },
      optimisticUpdater: (store) => updateAfterCreate(store, viewer!, variables, deck),
      updater: (store) => updateAfterCreate(store, viewer!, variables, returnDeck ? undefined : deck),
      onCompleted: (data) => {
        deck?.front && dispatch(setCanRecommend(deck.front, !!isLastSetCard));
        onComplete && onComplete(data);
        if (variables.deleted) return;
        if ((deck?.stats?.total || 0) >= FIRSTCARDSCOUNT) {
          seeHint(FIVEHINT);
          if (!hintWasSeen(FIRSTLOOPDONE) && !hintWasSeen(TUTORIALDONE)) {
            setTimeout(() => navigate(MAIN.url(), { replace: true }));
          }
        }
        if (!viewer?.email && deck!.stats.known > 10 && deck!.stats.total % 4 === 0 && !inLearn) {
          setTimeout(() =>
            // timeouted so it will be later than success alert (workaround)
            snackbar(
              <Box textAlign="center">
                <Trans parent="p">
                  You are still using a guest account. We recommend you to register or you can lose all your cards and
                  progress if your login expires
                </Trans>
                <Button variant="outlined" size="small" onClick={() => navigate(PROFILE.url())}>
                  <Trans>Register</Trans>
                </Button>
              </Box>,
              {
                severity: "warning"
              }
            )
          );
        }

        if (deck!.id !== variables.deckId) {
          setDeckId(variables.deckId);
        }
      }
    });
  };

  return {
    cardCreate,
    cardData,
    sCardData,
    loading: cardLoading || sCardLoading
  };
};

function updateAfterCreate(
  store: RecordSourceSelectorProxy<cardCreateMutation$data>,
  viewer: Viewer,
  variables: cardCreateMutation["variables"],
  deck?: viewerQuery_decks$data["decks"][0]
) {
  updateStoredDeckStats(store, variables, deck);
  updateSCard(store, variables);
  updateViewerXPS(store, viewer!, variables);
}

function updateSCard(
  store: RecordSourceSelectorProxy<cardCreateMutation$data>,
  variables: cardCreateMutation["variables"]
) {
  const sCardRec = variables.sCardId && store.get(variables.sCardId);
  sCardRec && sCardRec.setValue(!variables.deleted, "isInMyDeck", { deckId: variables.deckId });
}

function updateViewerXPS(
  store: RecordSourceSelectorProxy<cardCreateMutation$data>,
  viewer: Viewer,
  variables: cardCreateMutation["variables"]
) {
  if (variables.deleted) return;

  const days = store.get(viewer.id)?.getLinkedRecord("xps")?.getLinkedRecords("days");
  const lastDay = days?.[days?.length - 1];
  const lastDayAdded = Number(lastDay?.getValue("added")) || 0;
  lastDay?.setValue(lastDayAdded + 1, "added");
}

function updateStoredDeckStats(
  store: RecordSourceSelectorProxy<cardCreateMutation$data>,
  variables: cardCreateMutation["variables"],
  deck?: viewerQuery_decks$data["decks"][0]
) {
  if (variables.deleted || !deck) return;

  const deckRec = store.get(deck.id);
  if (deckRec) {
    const stats = deckRec.getLinkedRecord("stats");
    stats?.setValue(Number(deck.stats.total) + 1, "total");
    stats?.setValue(Number(deck.stats.unknown) + 1, "unknown");
  }
}
