import { Button } from "@mui/material";
import { ComponentProps } from "react";
import styled from "styled-components/macro";

const WhiteButton = styled(Button)`
  height: 56px;
  border-radius: 50px;
  background: #fff !important;
  color: #1e1e1e;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.32px;
`;

export interface LoginButtonProps extends ComponentProps<typeof Button> {}

export const LoginButton = ({ children, ...props }: LoginButtonProps) => (
  <WhiteButton {...props} color="primary" variant="outlined">
    {children}
  </WhiteButton>
);

export const SecondaryLoginButton = styled(Button)`
  margin-top: 32px;
`;
