import { useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router";
import useOnboardingData from "hooks/useOnboardingData";
import { getStepFromHash } from "./OnboardingRedirect";
import { StepScreenId } from "./onboardingStepsData";

const useOnboardingStepTracker = () => {
  const location = useLocation();
  const { getOnboardingData, saveOnboardingData } = useOnboardingData();
  const currentStepRef = useRef<StepScreenId | undefined>(undefined);

  const updateOnboardingData = useCallback(
    (step: StepScreenId | undefined) => {
      const { latestStepReached } = getOnboardingData();

      const getNewLatestStepReached = () => {
        const stepTransitions = {
          [StepScreenId.PREPARED_COURSE]: StepScreenId.PREPARED_COURSE_OPENED,
          [StepScreenId.PREPARED_VIDEO]: StepScreenId.PREPARED_VIDEO_OPENED,
          [StepScreenId.PREPARED_ARTICLE]: StepScreenId.PREPARED_ARTICLE_OPENED
        };

        const stepIndex = step ? Object.values(StepScreenId).indexOf(step) : -1;
        const latestStepReachedIndex = latestStepReached ? Object.values(StepScreenId).indexOf(latestStepReached) : -1;

        if (stepIndex > latestStepReachedIndex) {
          return step;
        }

        if (!step && latestStepReached) {
          return stepTransitions[latestStepReached] || latestStepReached;
        }

        return latestStepReached;
      };

      saveOnboardingData({
        latestStepReached: getNewLatestStepReached(),
        currentStep: step
      });
    },
    [getOnboardingData, saveOnboardingData]
  );

  useEffect(() => {
    const step = getStepFromHash(location.hash);

    if (currentStepRef.current !== step?.screenId) {
      updateOnboardingData(step?.screenId);
      currentStepRef.current = step?.screenId;
    }
  }, [location, updateOnboardingData]);

  return;
};

export default useOnboardingStepTracker;
