import React, { useEffect } from "react";
import { Environment, Network, RecordSource, Store, FetchFunction } from "relay-runtime";
import { useDispatch } from "react-redux";
import { logout, useSetState, addConnectionIssue, useGetState } from "../components/ReduxProvider";
import { useTranslation } from "react-i18next";
import { snackbar } from "tools/events";
import { useNetworkState, useMountedState, useUnmount } from "react-use";
import { sentryCapture } from "sentry/sentry";
import { isEqual } from "lodash";

/**
 * for advanced features we could use react-relay-network-modern in the future
 * https://github.com/relay-tools/react-relay-network-modern
 */

const requestTimes: { [key: string]: number } = {};

export const useEnvironment = () => {
  const isMounted = useMountedState();
  const dispatch = useDispatch();
  const token = useGetState("token");
  const { t } = useTranslation();
  const setConnectionProblem = useSetState("connectionProblem");
  const setReleaseId = useSetState("releaseId");
  const needsUpdate = useGetState("needsUpdate");
  const network = useNetworkState();

  const controllers = React.useRef<AbortController[]>([]); // to abort fetches on unmount

  const allowedToFetch = React.useRef(true);
  const allowedToRefetch = React.useRef(true);

  useUnmount(() => {
    allowedToFetch.current = false;
    controllers.current.forEach((c) => c.abort());
  });

  useEffect(() => {
    if (needsUpdate) {
      allowedToRefetch.current = false;
    }
  }, [needsUpdate]);

  const fetchQuery: FetchFunction = React.useMemo(
    () => (operation, variables, cacheConfig) => {
      const reqTime = Date.now();

      checkLooping(operation.name, variables);

      const apiUrl = (process.env.REACT_APP_API || "http://localhost:4000/graphql") + "?" + operation.name;

      const fetchWithRetry = async (options?: { isLastAttempt?: boolean; retryTimeout?: number }): Promise<any> => {
        if (!allowedToFetch.current) return;
        const controller = window.AbortController ? new window.AbortController() : null;
        if (controller) controllers.current.push(controller);
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              ...(token ? { Authorization: "Bearer " + token } : undefined)
            },
            body: JSON.stringify({
              query: operation.text,
              variables
            }),
            signal: controller?.signal
          });
          const json = await response.json();
          return json;
        } catch (error: Error | any) {
          if (!options?.isLastAttempt && isMounted() && error.name !== "AbortError") {
            await new Promise((resolve) => setTimeout(resolve, options?.retryTimeout || 0));
            return fetchWithRetry({ isLastAttempt: true });
          } else {
            throw error;
          }
        } finally {
          if (controller) controllers.current = controllers.current.filter((c) => c !== controller);
        }
      };

      return fetchWithRetry({ retryTimeout: 100, isLastAttempt: !allowedToRefetch.current }) // retry once after 100ms
        .then((json) => {
          if (!allowedToFetch.current) return null;
          //
          // throw Error("offline simulation");
          //
          const lastUpdateTime: number | undefined = requestTimes[operation.name];
          const nonSkippable = [
            "sourceQuery", // is called multiple-times in adminTranslations
            "fetchTtsQuery", // preloader requests multipletimes
            "useTranslatesQuery" // feature hints requests translate multipletimes
          ];
          if (lastUpdateTime && lastUpdateTime > reqTime && !nonSkippable.includes(operation.name)) {
            console.log("skipped response by older/faster " + operation.name + " -> ignore the TypeError in console");
            return null;
          } else {
            requestTimes[operation.name] = reqTime;
          }

          // releaseId is not set in "Something went wrong." (caused usually by GraphQL-obsolete) -> we use this behaviour to refresh FE using releaseId
          if (!json?.extensions?.releaseId) {
            // const refreshEvery5Minutes = Math.round(new Date().getMinutes() / 5);
            // setReleaseId("error-refresher-" + refreshEvery5Minutes);
            sentryCapture("Unknown server issue causing undefined releaseId", { json });
            throw new Error("probably deprecated graphQl = reset releaseId -> refreshJS on FE"); // will be catched below and not shown anywhere (until it happens 3x in 1min)
          }
          setReleaseId(json.extensions.releaseId);

          setConnectionProblem(false);

          if (json.errors) {
            // not possible to supress error at the moment
            // try to this need in general
            snackbar(t(json.errors[0].message), { autoHide: false, severity: "error" });

            json.errors[0].status === 401 && token && dispatch(logout());
          }
          return json;
        })
        .catch((err) => {
          if (!allowedToFetch.current) return null;
          setConnectionProblem(true);
          dispatch(
            addConnectionIssue(`fetchQuery: ${operation.name} failed: ${err.message}`, {
              fetchQuery: operation.name,
              networkOnline: network.online,
              networkType: network.type,
              networkEffectiveType: network.effectiveType,
              networkDownlink: network.downlink,
              networkRtt: network.rtt,
              networkDownlinkMax: network.downlinkMax
            })
          );
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, dispatch]
  );

  return React.useMemo(
    () =>
      new Environment({
        network: Network.create(fetchQuery),
        store: new Store(new RecordSource())
      }),
    [fetchQuery]
  );
};

let lastRequests: { operationName: string; variables: any; created: number }[] = [];
let captured = false;
// reports to sentry when there is the same query with same variables called at least 5 times in 10 seconds
const checkLooping = (operationName: string, variables: any) => {
  if (captured) return;

  lastRequests = lastRequests.filter((req) => req.created > Date.now() - 20_000);

  const loopsCount = lastRequests.filter(
    (req) => req.operationName === operationName && isEqual(req.variables, variables)
  ).length;

  if (loopsCount + 1 >= 20) {
    captured = true;
    sentryCapture(`Request looping in last 10 seconds (${operationName})`, { operationName, variables });
  }

  lastRequests.push({
    operationName,
    variables,
    created: Date.now()
  });
};
