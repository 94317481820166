import React from "react";
import styled, { css, keyframes, DefaultTheme } from "styled-components";
import MammothNew from "icons/MammothNew";
import { useNavigate } from "react-router-dom";
import { MAIN } from "root/RootRouter.config";
export enum MammothVariant {
  Small = "small",
  Large = "large"
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const MammothContainer = styled.div<{ $variant: MammothVariant }>`
  display: flex;
  align-items: ${({ $variant }) => ($variant === MammothVariant.Small ? "flex-start" : "center")};
  justify-content: ${({ $variant }) => ($variant === MammothVariant.Small ? "flex-start" : "center")};
  flex-direction: ${({ $variant }) => ($variant === MammothVariant.Small ? "row-reverse" : "column")};
  gap: 20px;
`;

const Mammoth = styled(MammothNew)<{ $variant: MammothVariant }>`
  min-width: 100px;
  width: ${({ $variant }) => ($variant === MammothVariant.Small ? "30%" : "60%")};
  transform: scaleX(-1);
  margin: 0 auto;
  max-width: ${({ $variant }) => ($variant === MammothVariant.Large ? "auto" : "100px")};
`;

const speechBubbleVariantStyles = {
  [MammothVariant.Large]: css`
    flex: 1;
    padding: 10px 20px;
    text-align: center;
    width: 235px;
  `,
  [MammothVariant.Small]: css`
    flex: 3;
    max-width: 250px;
    padding: 20px;
    text-align: left;
  `
};

const getSpeechBubbleArrowStyles = ($variant: MammothVariant, theme: DefaultTheme) => {
  switch ($variant) {
    case MammothVariant.Small:
      return css`
        left: -8px;
        top: 23px;
        width: 15px;
        height: 15px;
        border: 1px solid ${theme.duo.palette.blueMedium};
        background-color: ${theme.duo.color.white};
        transform: rotate(45deg);
        border-right-color: ${theme.duo.color.white};
        border-top-color: ${theme.duo.color.white};
      `;
    case MammothVariant.Large:
      return css`
        bottom: -21px;
        right: 20px;
        width: 28px;
        height: 22px;
        background-image: url("https://imgs.duocards.com/onboarding/bubble-arrow.svg");
      `;
  }
};

const SpeechBubble = styled.div<{ $variant: MammothVariant }>`
  position: relative;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.duo.palette.blueMedium};
  border-radius: 10px;
  font-family: "Noto Sans", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  animation: ${fadeIn} 1s ease-in-out;

  ${({ $variant }) => speechBubbleVariantStyles[$variant]}

  &::after {
    content: "";
    position: absolute;
    ${({ $variant, theme }) => getSpeechBubbleArrowStyles($variant, theme)}
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

type Props = {
  variant: MammothVariant;
  children: React.ReactNode;
};

const MammothWithBubble: React.FC<Props> = ({ children, variant }) => {
  const navigate = useNavigate();

  // click on mammoth to finish tutorial
  const handleClick = () => {
    if (process.env.NODE_ENV !== "development") return;
    const event = new KeyboardEvent("keydown", {
      code: "KeyD",
      ctrlKey: true,
      bubbles: true,
      cancelable: true
    });
    document.dispatchEvent(event);
    navigate(MAIN.url(), { replace: true });
  };

  return (
    <MammothContainer $variant={variant}>
      <SpeechBubble $variant={variant}>{children}</SpeechBubble>
      <Mammoth $variant={variant} onClick={handleClick} />
    </MammothContainer>
  );
};

export default MammothWithBubble;
