import { HeaderLayout } from "components/header/Header";
import React from "react";
import styled from "styled-components/macro";
import { Trans, useTranslation } from "react-i18next";
import { useViewerQuery } from "queries/viewerQuery";
import Loader from "components/other/Loader";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation, useQuery } from "relay-hooks";
import { AffiliateStatsQuery } from "root/profile/__generated__/AffiliateStatsQuery.graphql";
import CopyField from "components/form/CopyField";
import { REFERRER_PARAM } from "../../components/initializers/ReferrerCounter.model";
import { AFFILIATE_MINIMUM_PAYMENT } from "sharedJs__generated/constants";
import { format } from "date-fns";
import { AffiliateAddPayoutMutation } from "./__generated__/AffiliateAddPayoutMutation.graphql";
import { snackbar } from "tools/events";
import FormatNumber from "components/formatters/FormatNumber";

const affiliateStatsQuery = graphql`
  query AffiliateStatsQuery {
    viewer {
      affiliateStats {
        clicksCount
        installsCount
        subscriptionsCount
        commission
        payout
      }
      payouts {
        amount
        created
      }
    }
  }
`;

export const addPayoutMutation = graphql`
  mutation AffiliateAddPayoutMutation($amount: Int!, $userId: String!) {
    addPayout(amount: $amount, userId: $userId) {
      affiliateStats {
        payout
      }
      payouts {
        amount
        created
      }
    }
  }
`;

const Block = styled.div<{ last?: boolean }>`
  max-width: 80%;
  margin: 0 auto;
  padding-bottom: ${(p) => (p.last ? "40px;" : "0")};
  display: flex;
  flex-flow: column;
`;
const Stats = styled.div`
  clip-path: polygon(0 0, 100% 0, 70% 58%, 70% 85%, 30% 100%, 30% 58%);
  text-align: center;
  color: #fff;
  & > div:nth-child(1) {
    background-color: #008300;
  }
  & > div:nth-child(2) {
    background-color: #0079af;
  }
  & > div:nth-child(3) {
    background-color: #b59f0f;
    padding-bottom: 30px;
    padding-top: 20px;
  }
`;
const Stat = styled.div`
  margin: 2px 0;
  padding: 10px 0;
`;
const StatTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 110%;
  margin: 50px 0 30px 0;
  text-align: center;
`;
const Quiet = styled.div`
  font-size: 90%;
  line-height: 1.5em;
  color: ${({ theme }) => theme.duo.color.textGrey};
  margin-top: 10px;
`;
const SLoader = styled(Loader)`
  padding-top: 20px;
`;

const StatValue = styled.div``;

type Props = {};

const Affiliate: React.FC<Props> = () => {
  const { viewer } = useViewerQuery();
  const { data: affiliateResponse } = useQuery<AffiliateStatsQuery>(affiliateStatsQuery);
  const [addPayout, { loading: addPayoutLoading }] = useMutation<AffiliateAddPayoutMutation>(addPayoutMutation);
  const { t } = useTranslation();

  const affiliateStats = affiliateResponse?.viewer?.affiliateStats;
  if (!viewer?.name || !affiliateStats) return <Loader />;
  const affiliateLink =
    viewer.affiliate === "anthony"
      ? "https://go.duocards.com/anthony"
      : `https://get.duocards.com/r/${viewer.affiliate}`;
  const urlPostfix = `?${REFERRER_PARAM}=${viewer.affiliate}`;
  const sumOfPayouts = affiliateResponse.viewer.payouts.reduce(
    (acc, current) => Number(acc) + Number(current.amount),
    0
  );
  const availablePayout = affiliateStats.payout - sumOfPayouts > 0 ? affiliateStats.payout - sumOfPayouts : 0;

  const addPayoutHandler = async () => {
    if (!viewer) return;
    addPayout({
      variables: { userId: viewer.id, amount: availablePayout },
      onCompleted: () => {
        snackbar(
          <Trans>
            Your request for a payout of <FormatNumber value={availablePayout} />€ has been submitted successfully.
          </Trans>,
          {
            severity: "success"
          }
        );
      }
    });
  };

  return (
    <HeaderLayout title={<Trans>Partner program</Trans>} narrow>
      <Block>
        <Title>
          <Trans>Your partner link</Trans>
        </Title>
        <CopyField value={affiliateLink} />
        <Quiet>
          {t(`Share this link on social media or send it to your students.`)}&nbsp;
          {t(
            `You will earn a commission for everyone who clicks on the link, installs DuoCards and purchase a subscription.`
          )}
          <p>
            <Trans>Alternatively use any url from the App with postfix:</Trans>{" "}
            <span style={{ whiteSpace: "nowrap" }}>{urlPostfix}</span>
          </p>
        </Quiet>
        <CopyField value={`https://app.duocards.com/${urlPostfix}`} />
      </Block>

      <Block>
        <Title>{t("Statistics")}</Title>
        <Stats>
          <Stat style={{ backgroundColor: "#4466A3" }}>
            <StatTitle>{t("Clicks")}:</StatTitle>
            <StatValue>{affiliateStats.clicksCount}x</StatValue>
          </Stat>
          <Stat>
            <StatTitle>{t("Installations")}:</StatTitle>
            <StatValue>{affiliateStats.installsCount}x</StatValue>
          </Stat>
          <Stat>
            <StatTitle>{t("Subscriptions")}:</StatTitle>
            <StatValue>{affiliateStats.subscriptionsCount}x</StatValue>
          </Stat>
        </Stats>
      </Block>

      <Block>
        <Title>{t("Earnings")}</Title>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align="left">{t(`Your commission`)}</TableCell>
                <TableCell align="right" style={{ fontWeight: "bold" }}>
                  {affiliateStats.commission} %
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">{t(`Minimum payment`)}</TableCell>
                <TableCell align="right" style={{ fontWeight: "bold" }}>
                  {AFFILIATE_MINIMUM_PAYMENT} €
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">{t(`Ready for payout`)}</TableCell>
                <TableCell align="right" style={{ fontWeight: "bold" }}>
                  {availablePayout} €
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Block>

      <Block last>
        {!addPayoutLoading ? (
          <Button
            sx={{ mt: 3, mb: 2 }}
            size="small"
            variant="contained"
            color="primary"
            onClick={addPayoutHandler}
            disabled={availablePayout < AFFILIATE_MINIMUM_PAYMENT}
          >
            {t("Ask for payout")}
          </Button>
        ) : (
          <SLoader size={35} />
        )}

        {affiliateResponse.viewer.payouts.length > 0 && (
          <>
            <Title>{t("Cashed")}</Title>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {affiliateResponse.viewer.payouts.map((payout, i) => (
                    <TableRow key={i}>
                      <TableCell align="left">
                        {payout.created && format(new Date(payout.created), "d. M. yyyy")}
                      </TableCell>
                      <TableCell align="right" style={{ fontWeight: "bold" }}>
                        {payout.amount}€
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Block>
    </HeaderLayout>
  );
};

export default Affiliate;
