import { createTheme } from "@mui/material/styles";
import { Theme } from "../components/ReduxProvider";

//TODO: we should remove this theme and use variables directly from MUI

export const getTheme = (theme: Theme = "default") => ({
  color: {
    primary: "#0099dd",
    primaryGradient: "linear-gradient(45deg, #0099dd 30%, #0ae 90%)",
    themeGradient: "linear-gradient(135deg, #0099dd 30%, #00aa44 90%)",
    lightBlue: "#77bbff",
    grey: "#e2edf5",
    lightGrey: "#f3f9ff",
    text: "#333333",
    textGrey: "#aaaaaa",
    textDelete: "#808080",
    textAccent: "#d46e22",
    gold: "#d3b000",
    warning: "#f5a34b",
    green: theme === "sepia" ? "#aa77ee" : "#009900",
    beginnerGreen: "#77dd00",
    easyGreen: "#009944",
    duoToneGreen: "#57bc57",
    lightGreen: "#00990022",
    red: "#dd4444",
    admin: "purple",
    flame: "#ef5a00",
    star: "#92D2F1",
    white: "#ffffff",
    secondLevelBg: "#F0F0F0",
    shadow: "#0004"
  },
  palette: {
    // pallete based on michaels color pallete https://www.figma.com/design/IrlRqVBweBRerdQTR4kY6u/Components-library?node-id=6-105&t=5J5i8k3NqBHjwDu4-0
    white: "#ffffff",
    greyDark: "#757575",
    greyLight: "#E2E2E0",
    blueMain: "#00A1E6",
    blueVeryLight: "#f4f8fe",
    blueVeryLight2: "#ecf2f9",
    blueLighter: "#e4ecf4",
    blueMedium: "#C1DCFB",
    blueMediumLight: "#C1E4FF"
  },

  fontSize: {
    smallest: "11.5px",
    smaller: "12px",
    small: "13.5px",
    normal: "16px",
    medium: "18px",
    large: "22px"
  },
  fontWeight: {
    w400: 400,
    w700: 700
  },
  borderRadius: {
    normal: "12px"
  },
  boxShadow: {
    normal: "0.7px 0.7px 3.5px 1px rgba(0,0,0,0.18)"
  },
  border: {
    grey: "1px solid #e3e2e0"
  },
  maxWidth: {
    normal: "800px",
    narrow: "520px"
  },
  maxHeight: {
    normal: "1000px"
  },
  theme
});

// TODO: REFACTOR .duo. from our codebase and repace with pallete etc.

export const muiTheme = (themeState: Theme) =>
  createTheme({
    palette: {
      primary: {
        main: getTheme(themeState).color.primary
      },
      secondary: {
        main: getTheme(themeState).color.green
      },
      text: {
        secondary: getTheme(themeState).color.textGrey
      },
      info: { main: "#ffffff" }
    },
    // @ts-ignore
    duo: getTheme(themeState),
    components: {
      MuiAlert: {
        styleOverrides: {
          icon: {
            alignItems: "center"
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: "8px 26px",
            borderRadius: 100,
            flexShrink: 0
          },
          containedPrimary: {
            ":not(.Mui-disabled)": {
              background: getTheme(themeState).color.primaryGradient
            }
          },
          // Add this style for disabled buttons
          contained: {
            "&.Mui-disabled": {
              backgroundColor: "#ccc",
              color: "white"
            }
          }
          // THIS IS NOT WORKING SO IT'S IN GLOBAL STYLES:
          // sizeLarge: {
          //   padding: "11px 32px",
          //   fontSize: "16px"
          // }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            border: getTheme(themeState).border.grey
          }
        }
      }
    }
  });
