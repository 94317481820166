import { useViewerDecks, useViewerQuery } from "queries/viewerQuery";
import React from "react";
import { FIVEHINT, STARTID } from "root/main/main/Main";
import TutorialContent, { HintPosition, TutorialFC } from "./TutorialContent";
import { useMatch } from "react-router";
import { useMatchMain } from "root/RootRouter";
import FirstFewCards, { FIRSTFEWHINT } from "./steps/FirstFewCards";
import { SOURCE } from "root/library/library/libraryUrls";
import LongTouch from "./steps/LongTouch";
import { STARTLEARNING, StartLearning1, StartLearning2 } from "./steps/StartLearning";
import { FIRSTLOOPDONE } from "root/main/learn/Learn";
import { CARD, DECK, LEARN } from "root/main/MainRouter";
import PetMammoth, { PETMAMMOTH } from "./steps/PetMammoth";
import TapCard, { TAPTUTORIALDONE } from "./steps/TapCard";
import ThenSwipeAllRight from "./steps/ThenSwipeAllRight";
import { CARDSID } from "root/main/learn/Cards";
import Verdict, { SWIPINGDONE, TUTORIALDONE } from "./steps/Verdict";
import { VOICEHINT } from "components/other/HintTooltip";
import { useSeeHint } from "components/hint/Hint";
import Notifications, { NOTIFICATIONSDONE } from "root/profile/Notifications";
import AddingManually, { TRYMANUALHINT } from "./setting/AddingManually";
import SettingsDone from "./setting/SettingsDone";
import SetIntro, { SETINTROHINT } from "./setting/SetIntro";
import Motivation, { MOTIVATIONHINT } from "./setting/Motivation";
import { LIKEITHINT } from "root/main/main/LikeIt";
import { useEvent } from "react-use";
import { GENERATED_APP_LANG, PROFILE, VERIFY, ONBOARDING } from "../RootRouter.config";
import FeaturesHint from "./setting/FeaturesHint";
import useOnboardingStepTracker from "./onboarding/useOnboardingStepTracker";
import { useGetState } from "components/ReduxProvider";
import { useOnboardingInitialAndForceRedirect } from "./onboardingRedirects";
export const FIRSTCARDSCOUNT = 3;

const Tutorial: React.FC<{}> = () => {
  const { hintWasSeen, viewer } = useViewerQuery();
  const { deck } = useViewerDecks();
  const inProfile = useMatch(PROFILE.url() + "/*");
  const inSource = useMatch(SOURCE.query());
  const inMain = useMatchMain({ end: true });
  const inLearn = useMatch(LEARN.url());
  const inDeck = useMatch(DECK.url());
  const inVerify = useMatch(VERIFY.url());
  const inOnboarding = useMatch(ONBOARDING.url());
  const inGeneratedAppLang = useMatch(GENERATED_APP_LANG.url());
  const inCard = useMatch(CARD.url());
  const seeHint = useSeeHint();
  const deviceUsersDialogOpen = useGetState("deviceUsersDialogOpen");
  useOnboardingStepTracker();

  const shouldSkipTutorial =
    hintWasSeen(TUTORIALDONE) ||
    hintWasSeen(VOICEHINT) ||
    inDeck ||
    inProfile ||
    inVerify ||
    inGeneratedAppLang ||
    inCard ||
    deviceUsersDialogOpen;

  const initialRedirectDone = useOnboardingInitialAndForceRedirect(!!shouldSkipTutorial);

  const { getFlag } = useViewerQuery();
  const featuresScreen = getFlag("abtest_featuresScreen")?.value === "on";

  // finishing tutorial on ctrl+D
  const finishTutorial = (e) => {
    if (process.env.NODE_ENV !== "development") return;
    if ((e.metaKey || e.ctrlKey) && e.code === "KeyD" && !hintWasSeen(TUTORIALDONE)) {
      e.preventDefault();
      seeHint(TUTORIALDONE);
    }
  };
  useEvent("keydown", finishTutorial);

  React.useEffect(() => {
    if (!hintWasSeen(TUTORIALDONE) && (hintWasSeen(VOICEHINT) || hintWasSeen(LIKEITHINT))) {
      seeHint(TUTORIALDONE); // some historic users
    }
  }, [deck, hintWasSeen, seeHint]);

  if (shouldSkipTutorial || !initialRedirectDone || inOnboarding) {
    return null;
  }

  const config: {
    condition: () => any; // booleanish
    Component: TutorialFC;
    position?: HintPosition;
    visibleId?: string;
    movingMammoth?: boolean;
  }[] = [
    {
      condition: () => !hintWasSeen(FIRSTFEWHINT) && inSource,
      Component: FirstFewCards
    },
    {
      condition: () => inSource && !hintWasSeen(FIVEHINT),
      Component: LongTouch
    },
    {
      condition: () => !hintWasSeen(PETMAMMOTH),
      Component: PetMammoth
    },
    {
      condition: () => inMain && !hintWasSeen(STARTLEARNING),
      Component: StartLearning1
    },
    {
      condition: () => inMain && deck?.stats.unknown && !hintWasSeen(FIRSTLOOPDONE),
      Component: StartLearning2,
      position: "bottom",
      visibleId: STARTID
    },
    {
      condition: () => inLearn && !hintWasSeen(TAPTUTORIALDONE),
      Component: TapCard,
      position: "bottom"
    },
    {
      condition: () => inLearn && !hintWasSeen(FIRSTLOOPDONE),
      Component: ThenSwipeAllRight,
      visibleId: CARDSID,
      position: "bottom"
    },
    {
      condition: () => !hintWasSeen(SWIPINGDONE),
      Component: Verdict
    },
    {
      condition: () => !hintWasSeen(NOTIFICATIONSDONE),
      Component: (p) => <Notifications {...p} inTutorial style={{ minHeight: "calc(100% - 60px)" }} />,
      position: "full"
    },
    {
      condition: () => !featuresScreen && !hintWasSeen(TRYMANUALHINT),
      Component: AddingManually,
      position: "full"
    },
    {
      condition: () => !featuresScreen && !hintWasSeen(SETINTROHINT),
      Component: SetIntro,
      position: "full"
    },
    {
      condition: () => !featuresScreen && !hintWasSeen(MOTIVATIONHINT) && !viewer?.inviter,
      Component: Motivation,
      position: "full"
    },
    {
      condition: () => featuresScreen && !hintWasSeen(MOTIVATIONHINT),
      Component: FeaturesHint,
      position: "full"
    },
    {
      condition: () => true,
      Component: SettingsDone,
      position: "full"
    }
  ];

  const index = config.findIndex((cnf) => cnf.condition());
  const c = config[index];
  const settingsStartIndex = config.findIndex((c) => c.Component === Verdict) + 1;
  const fromIndex = 0;
  const startPercentage = 0; // prepared if we decide to start onboarding from somewhere else
  const remainingPercentage = 100 - startPercentage;
  const perc = `${startPercentage + (remainingPercentage / (settingsStartIndex - fromIndex)) * (index - fromIndex)}%`;
  const showProgressbar = settingsStartIndex > index && index >= fromIndex;

  if (!c) return null;

  return (
    <TutorialContent
      visibleId={c.visibleId}
      perc={perc}
      step={index}
      showProgressbar={showProgressbar}
      position={c.position}
      Component={c.Component}
    />
  );
};

export default Tutorial;
