import styled from "styled-components/macro";
import AppLang from "../../components/other/AppLang";
import { IconButton } from "@mui/material";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import React from "react";

const Wrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
`;

type Props = {
  onBack?: () => void;
  showBack?: boolean;
};

const LoginHeader = (props: Props) => {
  return (
    <Wrap>
      {props.showBack && (
        <IconButton sx={{ position: "absolute", top: 2, left: 0 }} onClick={props.onBack}>
          <KeyboardArrowLeftRoundedIcon />
        </IconButton>
      )}
      <div style={{ margin: "0 auto" }}>
        <AppLang />
      </div>
    </Wrap>
  );
};

export default LoginHeader;
