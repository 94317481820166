import { Trans } from "react-i18next";
import { motivationGigs } from "./misc/motivationGigs";
import { FIRSTPET } from "../../../../components/mammoth/PetableMammoth";
import { QuestBubble } from "./misc/QuestBubble";
import React from "react";
import { useViewerQuery } from "../../../../queries/viewerQuery";
import { useGetState, selectMotivationGig } from "../../../../components/ReduxProvider";
import { useSelector } from "react-redux";

export function MotivationBubble() {
  const { hintWasSeen } = useViewerQuery();
  const petting = useGetState("pettingMemo");
  const gigIndex = useSelector(selectMotivationGig);

  return (
    <QuestBubble petting={petting}>
      {<Trans>{motivationGigs[!hintWasSeen(FIRSTPET) ? 0 : gigIndex]}</Trans>}
    </QuestBubble>
  );
}
