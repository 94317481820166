import React from "react";
import styled from "styled-components/macro";
import { IconButton } from "@mui/material";
import RestartIcon from "@mui/icons-material/RestartAlt";
import { TextField } from "styled/TextField";
import BaseURLItem, { ActiveIndicator } from "root/admin/switchAppSource/BaseUrlItem";
import Row from "components/other/Row";
// import { SettingsRemote } from "@mui/icons-material";
import { HeaderLayout } from "components/header/Header";
import { windowReload } from "tools/windowReload";

const SRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  padding: 12px 20px;

  & > p {
    font-size: 11px;
    color: #999;
    margin: 0;
  }
`;

type Option = {
  value: string;
  label: string;
};

const baseURLOptions: Option[] = [
  { value: `https://app.duocards.com/static/app/main.chunk.js`, label: "Production" },
  { value: `https://duocards-fe-stg.herokuapp.com/static/app/main.chunk.js`, label: "Staging" },
  { value: `http://192.168.2.246:3000/static/js/bundle.js`, label: "Melounek" },
  { value: `http://192.168.2.246:3000/static/app/main.chunk.js`, label: "Melounek-Build" },
  { value: `http://192.168.0.127:3000/static/js/bundle.js`, label: "Radim doma" },
  { value: `Http://192.168.2.136:3000/static/js/bundle.js`, label: "Radim prace" },
  { value: `http://192.168.0.162:3000/static/js/bundle.js`, label: "Radek" },
  { value: `http://10.0.0.166:3000/static/js/bundle.js`, label: "Radek Doma" },
  { value: `http://192.168.2.179:3000/static/js/bundle.js`, label: "Martin" },
  { value: `http://192.168.1.148:3000/static/js/bundle.js`, label: "Martin Doma" },
  { value: `http://192.168.2.225:3000/static/js/bundle.js`, label: "Mira" },
  { value: `http://192.168.0.29:3000/static/js/bundle.js`, label: "Mira Doma" }
];

const runningBaseURL = localStorage.getItem("baseURL");
const isCustomRunningBaseURL: boolean = !baseURLOptions.find((option) => option.value === runningBaseURL);

const BaseUrlSettings = () => {
  const [customBaseURL, setCustomBaseURL] = React.useState<string>(runningBaseURL || ""); // last clicked URL or edited URL

  const reloadAppFrom = (baseURL: string): void => {
    localStorage.setItem("baseURL", baseURL);
    windowReload();
  };

  return (
    <HeaderLayout title="Switch app source">
      <>
        {!runningBaseURL && <Row>Currently running from default URL</Row>}
        {runningBaseURL && isCustomRunningBaseURL && (
          <SRow onClick={() => reloadAppFrom(runningBaseURL)}>
            <BaseURLItem label="Custom URL" url={runningBaseURL} isActive={true} />
            <IconButton onClick={() => reloadAppFrom(runningBaseURL)}>
              <RestartIcon />
            </IconButton>
          </SRow>
        )}
        {baseURLOptions.map((option) => (
          <SRow onClick={() => setCustomBaseURL(option.value)}>
            <BaseURLItem label={option.label} url={option.value} isActive={runningBaseURL === option.value} />
            <IconButton onClick={() => reloadAppFrom(option.value)}>
              <RestartIcon />
            </IconButton>
          </SRow>
        ))}
        <SRow>
          {customBaseURL === runningBaseURL && <ActiveIndicator />}
          <TextField
            label="Set custom app URL"
            value={customBaseURL}
            onChange={(e) => setCustomBaseURL(e.target.value)}
            style={{ flex: 1 }}
          />
          <IconButton onClick={() => reloadAppFrom(customBaseURL)}>
            <RestartIcon />
          </IconButton>
        </SRow>
      </>
    </HeaderLayout>
  );
};

export default BaseUrlSettings;
