import { Button } from "@mui/material";
import { useSeeHint } from "components/hint/Hint";
import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";
import { useAnimateTexts } from "hooks/useAnimateTexts";
import { INVITE_FRIEND_QUEST_PHASE_1 } from "./InviteFriendQuest.model";
import { dispatchEvent, EVENT } from "../../../../../tools/events";
import { useSetState } from "../../../../../components/ReduxProvider";
import { QuestMessage } from "../misc/QuestMessage";

const TextWrap = styled.div`
  margin: 22px 0 18px;
`;

export const InviteFriendQuestPhase1 = () => {
  const seeHint = useSeeHint();
  const setShowQuestsBackdrop = useSetState("showQuestsBackdrop");
  const [s0, s1] = useAnimateTexts({ delay: 1000 });

  React.useEffect(() => {
    setShowQuestsBackdrop(true);
    dispatchEvent(EVENT.updateMain);
  }, [setShowQuestsBackdrop]);

  return (
    <QuestMessage>
      <TextWrap>
        <div style={s0}>
          <Trans>I have another quest for you: Invite your friend and get a gift!</Trans>
        </div>
      </TextWrap>
      <div style={s1}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            seeHint(INVITE_FRIEND_QUEST_PHASE_1);
            setShowQuestsBackdrop(false);
            setTimeout(() => dispatchEvent(EVENT.updateMain));
          }}
        >
          <Trans>Let's do it!</Trans>
        </Button>
      </div>
    </QuestMessage>
  );
};
