import { Button, Box, Collapse, DialogContent } from "@mui/material";
import React, { useState, useMemo } from "react";
import RiveComponent, { Layout, Fit, Alignment } from "@rive-app/react-canvas";
import { Trans, useTranslation } from "react-i18next";
import { useCopyToClipboard } from "react-use";

import { snackbar } from "tools/events";
import {
  Title,
  SAnimation,
  SColoredHeader,
  SLink,
  Ul,
  SDialogFullWindow,
  ButtonsGroup
} from "./InviteDialogContent.styled";
import { isApp } from "../../../tools/device";

type Props = {
  open: boolean;
  hash: string;
  canAdvertFriends: boolean;
  isFromQuest: boolean;
  isSubscriber: boolean;
  onAction: (name: string) => void;
  onClose: () => void;
};

export function InviteDialogContent(props: Props) {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const copyToClipboard = useCopyToClipboard()[1];

  const url = `https://app.duocards.com/i/${props.hash}`;

  const inviteText = useMemo(() => {
    const text = t("Hi, connect with me in DuoCards. It is a great app for learning vocabulary:");
    const textPromoBoth = t("Hi, join me in DuoCards and we will both get one month of Premium for free:");
    const textPromoThey = t("Hi, join me in DuoCards and you will get one month of Premium for free:");

    if (props.isSubscriber) {
      return `${textPromoThey} ${url}`;
    }
    if (!props.isFromQuest && props.canAdvertFriends) {
      return `${textPromoBoth} ${url}`;
    }
    return `${text} ${url}`;
  }, [props.canAdvertFriends, props.isFromQuest, props.isSubscriber, t, url]);

  const renderTitle = () => {
    if (props.isSubscriber) {
      return (
        <Trans parent="strong">
          Invite friends and <SColoredHeader>they</SColoredHeader> get{" "}
          <SColoredHeader>a premium account</SColoredHeader> for <SColoredHeader>one month</SColoredHeader>!
        </Trans>
      );
    }

    if (!props.isFromQuest && props.canAdvertFriends) {
      return (
        <Trans parent="strong">
          Invite a friend and <SColoredHeader>you both</SColoredHeader> get a <SColoredHeader>Premium</SColoredHeader>{" "}
          account for <SColoredHeader>one month</SColoredHeader>!
        </Trans>
      );
    }

    return (
      <Trans parent="strong">
        Invite a friend <SColoredHeader>and get a gift</SColoredHeader>!
      </Trans>
    );
  };

  return (
    <SDialogFullWindow
      open={props.open}
      maxWidth={600}
      allowDesktopPopup
      consumeHeader
      background
      onClose={props.onClose}
    >
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box sx={{ flexGrow: 0.3, alignContent: "center" }}>
            <Title variant="h5">{renderTitle()}</Title>
          </Box>

          <Box sx={{ flex: "auto", alignContent: "center" }}>
            <SAnimation>
              <RiveComponent
                src="https://imgs.duocards.com/rive/invite-friend"
                layout={new Layout({ fit: Fit.FitHeight, alignment: Alignment.TopCenter })}
              />
            </SAnimation>
          </Box>

          <ButtonsGroup direction="column" spacing={2}>
            {isApp() && navigator.share !== undefined ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  props.onAction("Native share");
                  // @ts-ignore
                  navigator.share(inviteText, undefined, "text/plain");
                }}
              >
                <Trans>Invite a friend</Trans>
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  copyToClipboard(inviteText);
                  snackbar(<Trans>Copied to clipboard!</Trans>, { severity: "success" });
                  props.onAction("Copy link");
                }}
              >
                <Trans>Copy invitation</Trans>
              </Button>
            )}

            {props.canAdvertFriends && (
              <Box>
                <Box sx={{ textAlign: "center" }}>
                  <SLink onClick={() => setExpanded(!expanded)}>
                    <Trans>How does it work?</Trans>
                  </SLink>
                </Box>

                <Collapse in={expanded}>
                  <Ul>
                    <Trans parent="li">Your friend has to finish the registration and the tutorial.</Trans>
                    <Trans parent="li">
                      If you don't see your friend here after you send the link, ask what instructions your friend sees
                      on the main screen of the app.
                    </Trans>
                    <Trans parent="li">The offer is valid even if the invited friend is already registered</Trans>
                  </Ul>
                </Collapse>
              </Box>
            )}
          </ButtonsGroup>
        </Box>
      </DialogContent>
    </SDialogFullWindow>
  );
}
