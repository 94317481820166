import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";
import { Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { useViewerDecks } from "../../../queries/viewerQuery";
import { useDispatch } from "react-redux";
import { setQuestAddSource } from "components/ReduxProvider";
import { isBrowser, inAppBrowser } from "tools/device";
import { TRYSHARING } from "../../RootRouter.config";

const List = styled.div`
  margin: 0 auto 15px;
  width: fit-content;
`;
const Li = styled.div`
  font-size: 14px;
  line-height: 1.35em;
  text-align: left;
  display: flex;
  align-items: center;
  margin: 8px 0;
  svg {
    margin-right: 5px;
    color: ${({ theme }) => theme.duo.color.primary};
  }
`;
const Img = styled.img`
  width: 140px;
  height: 140px;
`;

type Props = {};

const BySharing: React.FC<Props> = () => {
  const { deck } = useViewerDecks();
  const dispatch = useDispatch();

  return (
    <>
      <div style={{ height: 30 }} />
      <Img src="https://cdn.duocards.com/svg/browser.svg" />
      <p>
        <Trans parent="strong">You can easily add new words from any other application!</Trans>
      </p>
      <List>
        <Li>
          <TouchAppIcon />
          <Trans>select any word by long touch</Trans>
        </Li>
        <Li>
          <MenuIcon />
          <Trans>pick "share" from the options</Trans>
        </Li>
      </List>

      {!isBrowser() ? (
        <>
          <Button
            variant={"contained"}
            size="small"
            onClick={() => {
              if (deck && inAppBrowser()) {
                inAppBrowser().open("https://app.duocards.com" + TRYSHARING.url(deck.front), "_system");
              }
              setTimeout(() => dispatch(setQuestAddSource("addBySharing")), 500);
            }}
          >
            <Trans>Go to the browser and try it</Trans>
          </Button>
          <div>
            <Button sx={{ color: "#777" }} onClick={() => dispatch(setQuestAddSource("addBySharing"))}>
              <Trans>Maybe later</Trans>
            </Button>
          </div>
        </>
      ) : (
        <div>
          <Button variant="contained" onClick={() => dispatch(setQuestAddSource("addBySharing"))}>
            <Trans>Continue</Trans>
          </Button>
        </div>
      )}
    </>
  );
};

export default BySharing;
