import Flames from "components/fire/Flames";
import React from "react";
import { Trans } from "react-i18next";
import { useDebounce, useEvent } from "react-use";
import styled, { keyframes, css } from "styled-components/macro";
import { EVENT } from "tools/events";
import { QuestBubble } from "./misc/QuestBubble";
import { QuestMessage } from "./misc/QuestMessage";
import { FIRSTPET } from "../../../../components/mammoth/PetableMammoth";
import { useViewerQuery } from "../../../../queries/viewerQuery";
import { useGetState } from "../../../../components/ReduxProvider";

export const DAILYXPS = 5;

const shakeKF = keyframes`
  10%, 90% {left: 2px; }
  25%, 75% {left: -4px;}
  50% {left: 4px;}
`;

const SFlames = styled(Flames)`
  display: inline-block;
  margin: 0 7px -7px 6px;
`;
const Earn = styled.span<{ shake?: boolean }>`
  line-height: 1.7em;
  position: relative;
  ${({ shake }) =>
    shake &&
    css`
      animation: ${shakeKF} 400ms;
    `}
`;
const TopReward = styled.div`
  position: absolute;
  left: calc(50% - 6px);
  top: -30px;
  font-size: 20px;
`;

type Props = {};

const DailyXps: React.FC<Props> = () => {
  const { hintWasSeen } = useViewerQuery();
  const hasPetBefore = hintWasSeen(FIRSTPET);
  const petting = useGetState("pettingMemo");

  const [expanded, setExpanded] = React.useState(!hasPetBefore);
  const [shakeIt, setShakeIt] = React.useState(false);

  useDebounce(() => shakeIt && setShakeIt(false), 500, [shakeIt]);

  const expandDailyQuest = React.useCallback(() => {
    if (!expanded) {
      setExpanded(true);
    } else if (!shakeIt) {
      setShakeIt(true);
    }
  }, [expanded, shakeIt]);

  useEvent(EVENT.expandDailyQuest, expandDailyQuest);

  const render = () => {
    return expanded ? (
      <Earn shake={shakeIt}>
        <Trans>
          Earn at least
          <SFlames strength={4} xps={5} />
          today, <br /> so you can pet me
        </Trans>
        {hasPetBefore ? <>&nbsp;🧡</> : <TopReward>🧡</TopReward>}
      </Earn>
    ) : (
      <span onClick={() => setExpanded(true)}>
        +
        <SFlames strength={4} xps={5} />
      </span>
    );
  };

  if (hasPetBefore) {
    return <QuestBubble petting={petting}>{render()}</QuestBubble>;
  }

  return <QuestMessage>{render()}</QuestMessage>;
};

export default DailyXps;
