import { Fade, Paper } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";

const SPaper = styled(Paper)`
  position: relative;
  padding: 16px 18px 14px !important;
  margin: 8px auto -5px;
  z-index: 10;
`;
const Arrow = styled.div`
  position: absolute;
  bottom: -6px;
  left: calc(50% - 57px);
  width: 10px;
  height: 10px;
  border: 1px solid #888;
  border-top: none;
  border-left: none;
  transform: rotate(45deg);
  background: white;
`;

type Props = {
  children: React.ReactNode;
};

export function QuestMessage({ children }: Props) {
  return (
    <Fade in appear timeout={1200}>
      <SPaper elevation={8}>
        {children}
        <Arrow />
      </SPaper>
    </Fade>
  );
}
