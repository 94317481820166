import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function useDisableNavBack() {
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      navigate(1); // Go forward in history if user tries to go back
    };

    // Push a dummy entry to history stack
    window.history.pushState(null, document.title, window.location.href);

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);
}

export default useDisableNavBack;
