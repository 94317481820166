import React from "react";
import styled from "styled-components/macro";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import BottomNavigation from "../components/other/BottomNavigation";
import { CARD, LEARN } from "./main/MainRouter";
import { useViewerDecks, useViewerQuery } from "queries/viewerQuery";
import RootRouterHistory from "./RootRouterHistory";
import { SOURCE } from "./library/library/libraryUrls";
import {
  useDisallowGoingBackFromLibraryInTutorial,
  useRedirectIfTutorialInLoop,
  useRedirectToDeckIfNoDecks
} from "./tutorial/tutorialRedirects";
import RootRouter, { useIsPublicUrl, useLibraryUrl } from "./RootRouter";
import { useNoCardsDialogIfNoCards } from "./other/useNoCardsDialogIfNoCards";
import {
  GENERATED_APP_LANG,
  LIBRARY,
  MAIN,
  TRYSHARING,
  VERIFY,
  LATE_REGISTRATION,
  ONBOARDING
} from "./RootRouter.config";
import { FEEDBACK, HELP_CENTER, PASSWORD, SUBSCRIPTION } from "./profile/ProfileRouter.config";

const Wrap = styled.div<{ withBg: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background: ${({ theme, withBg }) => (withBg ? theme.duo.color.lightGrey : "none")};
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
`;

const RootRouterWrapper = () => {
  const navigate = useNavigate();
  const { viewer } = useViewerQuery();
  const { deck } = useViewerDecks();
  const inCard = useMatch(CARD.url());
  const inLearn = useMatch(LEARN.url());
  const inLateRegistration = useMatch(LATE_REGISTRATION.url());
  const location = useLocation();
  const libraryUrl = useLibraryUrl();
  const isPublicUrl = useIsPublicUrl();
  const inOnboarding = useMatch(ONBOARDING.url());

  useRedirectIfTutorialInLoop();

  useNoCardsDialogIfNoCards();

  useDisallowGoingBackFromLibraryInTutorial();

  useRedirectToDeckIfNoDecks();

  let hideBottomNav = !deck || inCard || inLearn || inLateRegistration || inOnboarding;
  useMatch(FEEDBACK.url()) && (hideBottomNav = true);
  useMatch(HELP_CENTER.url()) && (hideBottomNav = true);
  useMatch(PASSWORD.url()) && (hideBottomNav = true);
  useMatch(SUBSCRIPTION.url()) && (hideBottomNav = true);
  useMatch(TRYSHARING.query()) && (hideBottomNav = true);
  useMatch(VERIFY.url()) && (hideBottomNav = true);
  useMatch(SOURCE.query()) && (hideBottomNav = true);
  useMatch(GENERATED_APP_LANG.url()) && (hideBottomNav = true);

  const navPath =
    location.pathname.indexOf(LIBRARY.url()) === 0 ? libraryUrl : "/" + location.pathname.substring(1).split("/")[0];

  return (
    <Wrap withBg={!!viewer || isPublicUrl}>
      <RootRouterHistory />

      <Content>
        <RootRouter />
      </Content>

      {!hideBottomNav && (
        <BottomNavigation
          onChange={(event, value) => navigate(value)}
          value={navPath.length > 1 ? navPath : MAIN.url()}
        />
      )}
    </Wrap>
  );
};

export default RootRouterWrapper;
