import { Button } from "@mui/material";
import { useSeeHint } from "components/hint/Hint";
import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";
import { dispatchEvent, EVENT } from "../../../../../tools/events";
import { useSetState } from "../../../../../components/ReduxProvider";
import { INVITE_FRIEND_QUEST } from "./InviteFriendQuest.model";
import { QuestMessage } from "../misc/QuestMessage";
import { useViewerXps } from "../../../../../queries/viewerQuery";

const TextWrap = styled.div`
  margin: 22px 0 18px;
`;

export const InviteFriendQuestPhase3 = () => {
  const seeHint = useSeeHint();
  const setShowQuestsBackdrop = useSetState("showQuestsBackdrop");
  const { xps } = useViewerXps();
  const setLastQuestXps = useSetState("lastQuestXps");

  React.useEffect(() => {
    setShowQuestsBackdrop(true);
    dispatchEvent(EVENT.updateMain);
  }, [setShowQuestsBackdrop]);

  return (
    <QuestMessage>
      <TextWrap>
        <Trans>Now let's wait for your friend to finish tutorial.</Trans>
      </TextWrap>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          seeHint(INVITE_FRIEND_QUEST);
          setShowQuestsBackdrop(false);
          setTimeout(() => dispatchEvent(EVENT.updateMain));
          setLastQuestXps(xps?.total || 0);
        }}
      >
        <Trans>Continue learning</Trans>
      </Button>
    </QuestMessage>
  );
};
