import React from "react";
import { STREAK_FREEZE_QUEST } from "sharedJs__generated/constants";
import { useViewerQuery } from "../../../../../queries/viewerQuery";
import { StreakFreezeAssignment } from "./StreakFreezeAssignment";
import { StreakFreezeBubble } from "./StreakFreezeBubble";

type Props = {};

const StreakFreezeQuest: React.FC<Props> = () => {
  const { hintWasSeen } = useViewerQuery();

  if (!hintWasSeen(STREAK_FREEZE_QUEST)) {
    return <StreakFreezeAssignment />;
  }

  return <StreakFreezeBubble />;
};

export default StreakFreezeQuest;
