import React from "react";
import styled from "styled-components/macro";
import { Button, Portal, Stack } from "@mui/material";
import Confetti from "react-confetti";
import DialogWindow from "components/other/DialogWindow";
import Snowfall from "react-snowfall";
import { Trans } from "react-i18next";
import Countdown from "components/other/Countdown";
import { useGetState, useSetState } from "components/ReduxProvider";
import { useSaleExpiration } from "./subscriptionHooks";
import { useViewerQuery } from "queries/viewerQuery";
import { isNoPaymentTimezone } from "components/other/VpnAffiliate";

const Title = styled.h1`
  font-size: 24px;
  margin: 0;
  text-transform: uppercase;
`;
const Hero = styled.p`
  font-size: 36px;
  margin: 0;
  font-weight: bold;
`;
const DiagonalBG = styled.div<{ $black: boolean }>`
  position: absolute;
  top: -3px;
  left: -3px;
  width: 110%;
  height: 220px;
  background-color: ${(p) => (p.$black ? "#333" : "#ffa600")};
  background: linear-gradient(
    to right,
    ${(p) => (p.$black ? "#333" : "#ffd600")},
    ${(p) => (p.$black ? "#000" : "#ffa600")}
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
`;
const OfferTop = styled.div<{ $black: boolean }>`
  position: absolute;
  width: 100%;
  height: 200px;
  left: 0;
  top: 20px;
  ${(p) => (p.$black ? "color: #fff" : "")}
`;
const OfferBottom = styled.div`
  margin-top: 200px;
`;
const ExpirationTitle = styled.h2`
  font-size: 22px;
`;
const Expiration = styled.div`
  font-size: 26px;
  margin-bottom: 30px;
`;
const OfferWrap = styled.div`
  background-color: #fff;
  margin: -45px -15px -17px;
  padding: 40px 30px;
  width: 290px;
  overflow: hidden;
  position: relative;
`;
const SImg = styled.img<{ $black: boolean }>`
  max-width: 75px;
  ${(p) => (p.$black ? "filter: brightness(0) invert(100%);" : "")}
`;
const SButtonWrap = styled.div`
  margin-top: 85px;
`;

type Props = {};

const SaleDialog: React.FC<Props> = () => {
  const closeSale = useGetState("closeSale");
  const setCloseSale = useSetState("closeSale");
  const { saleExpiration, saleDiscount, saleTitle, saleId, saleActive } = useSaleExpiration();
  const { viewer } = useViewerQuery();

  if (closeSale || viewer?.subscription || !saleActive || isNoPaymentTimezone(viewer?.timezone) || !saleExpiration)
    return null;

  if (viewer?.installReferrer) return null;

  const handleClose = () => setCloseSale(true);

  return (
    <>
      <Portal>
        <Confetti
          confettiSource={{ x: 0, y: 0, w: window.innerWidth, h: 150 }}
          numberOfPieces={80}
          gravity={0.15}
          recycle={false}
          opacity={0.7}
          style={{ zIndex: 1310 }} // right behind snackbar
        />
      </Portal>
      <DialogWindow open onClose={handleClose}>
        <OfferWrap>
          <DiagonalBG $black={saleId === "blackFriday"}>
            <Snowfall color={saleId === "blackFriday" ? "#eeeeee" : "#CE8E00"} snowflakeCount={20} />
          </DiagonalBG>
          <OfferTop $black={saleId === "blackFriday"}>
            <Stack direction="column" justifyContent="space-around" alignItems="center" style={{ height: "135px" }}>
              <SImg $black={saleId === "blackFriday"} src="https://cdn.duocards.com/img/crown.png" />
              <Title>
                <Trans>{saleTitle}</Trans>!
              </Title>
              <Hero>- {saleDiscount} %</Hero>
            </Stack>
          </OfferTop>
          <OfferBottom>
            <ExpirationTitle>
              <Trans>Expires in</Trans>
            </ExpirationTitle>
            <Expiration>
              <Countdown seconds={saleExpiration} />
            </Expiration>
            <SButtonWrap>
              <Button color="primary" variant="outlined" onClick={handleClose}>
                <Trans>Continue</Trans>
              </Button>
            </SButtonWrap>
          </OfferBottom>
        </OfferWrap>
      </DialogWindow>
    </>
  );
};

export default SaleDialog;
